import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  ComponentFactoryResolver,
  TemplateRef,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { defer, from, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  findIndex,
  isEqual,
  filter as _filter,
  assign,
  chunk,
  isError
} from 'lodash-es';

import { EsMemberVirutualScrollStrategy } from './es-member-virutual-scroll-strategy';
import { environment } from '@lu/environment';
import { Path } from '@lu/path';
import {
  ServicePermissions,
  Group,
  Member,
  BankAccount,
  Address,
  GroupPermissions,
  NewAddress,
  AdminUser,
  MemberConfidential,
} from '@lu/models';
import { UserBatchRegistrationComponent, ReadProfile } from '@lu/components/user-batch-registration/user-batch-registration.component';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { UserSearchComponent } from '@lu/components/user-search/user-search.component';
import { MemberService } from '@lu/services/member.service';
import { MemberInviteDialogComponent } from '@lu/components/member-invite-dialog/member-invite-dialog.component';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';
import { ConfirmationDialogData } from '@lu/components/confirmation-dialog/confirmation-dialog.component';
import { CsvdlItemsSelectableComponent } from '@lu/components/csvdl-items-selectable/csvdl-items-selectable.component';
import * as moment from 'moment';
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import { PasswordConfirmService } from '@lu/services/password-confirm.service';

@Component({
  selector: 'lu-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [
    EsMemberVirutualScrollStrategy,
    DialogService,
  ]
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild('memberSearch', { static: true }) public memberSearch: UserSearchComponent;
  @ViewChild('loadedUserList', { static: false }) private loadedUserList: TemplateRef<HTMLElement>;
  @ViewChild('userBatchContainer', { static: false, read: ViewContainerRef }) private userBatchContainer: ViewContainerRef;
  @ViewChild('accordion', { static: false }) private accordion: ElementRef;
  @ViewChild('accordionHead', { static: false }) private accordionHead: ElementRef;
  @ViewChild('createFileInput', { static: false }) private createFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('updateFileInput', { static: false }) private updateFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('csvData', { static: true }) public csvData: CsvdlItemsSelectableComponent;
  public serviceAdmin = serviceAdmin;
  public groupAdmin = groupManager;
  public groupEditor = groupEditor;
  public userSearchForm = new FormGroup({});
  public uid: string;
  public userList: Member[];
  public servicePermissions: ServicePermissions | GroupPermissions;
  public permiss: any;
  private groupList: Array<Group>;
  public pageSize = 36;
  public membersTotal: number;
  public dataTotal: any;
  public accordionOpened = false;
  public readonly path = Path;
  public sendWelcomeMail = false; // v1.0.0
  public userSearchedData: any;
  public isDisabledDownloadBtn: any = false;
  public csvHeadersValue: any;
  private headers = [
    'ID', 'ニックネーム（表示名）', 'キャッチコピー', 'プロフィール', 'ハピコミュ登録日', 'BlogURL',
    'Instagramアカウント1', 'タグ', '氏名', '氏名(ふりがな)', '郵便番号', '住所', 'メールアドレス1', 'メールアドレス2',
    '電話番号1', '電話番号2', '連携用アカウント', '会員ステータス', '性別', '生年月日', '生年月日の非公開', '結婚状況',
    '子の有無', '子供の生年月日', '職業', '業種', '職種', '身長', '体重', '媒体', '編集部用タグ', '編集部備考', 'PRボード',
    '公開日時', 'Twitterアカウント', 'Instagramアカウント2', 'Youtubeアカウント', 'Tiktokアカウント', 'その他URL1',
    'その他URL2', 'その他URL3', '備考1', '備考2'
  ];

  constructor(
    public dataSource: EsMemberVirutualScrollStrategy,
    private title: Title,
    private renderer2: Renderer2,
    private aRoute: ActivatedRoute,
    private dialog: DialogService,
    private CSVService: XLXSCSVService,
    private memberService: MemberService,
    private componentResolver: ComponentFactoryResolver,
    private apiService: MatchingService,
    private router: Router,
    private passwordConfirmService: PasswordConfirmService
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe(data => {
      console.log(data.servicePermissions)
      this.permiss = data.servicePermissions;
      if (this.permiss.length > 0) {
        switch (this.permiss[0].role) {
          case AdminUser.RoleEnum.ServiceAdmin:
            this.servicePermissions = serviceAdmin;
            break;
          case AdminUser.RoleEnum.GroupEditor:
            this.servicePermissions = groupEditor;
            break;
          case AdminUser.RoleEnum.GroupAdmin:
            this.servicePermissions = groupManager;
            break;
          default:
            this.servicePermissions = defaultPermissions;
            break;
        }
      } else {
        this.servicePermissions = defaultPermissions;
      }
      this.groupList = data.groups;
      this.userSearchedData = data.usersearch;
    });
    if (!!this.userSearchedData) {
      this.memberSearch.followersCountStepsForm.setValue(this.userSearchedData.followersCountData);
      this.memberSearch.userSearchForm.setValue(this.userSearchedData.data);
    }
    this.setTitle();
    this.getMembersTotal();
    if (this.userSearchedData) {
      this.search();
    }
  }

  changeCheckboxData() {
    const checkbox = [...this.csvData.basicInfoList, ...this.csvData.personalInfoList, ...this.csvData.profileList
      , ...this.csvData.otherList];
    const headers = this.memberService.getHeaders();
    let csvHeaders = [];
    let csvHeadersValue = [];
    checkbox.map(checkData => {
      csvHeaders = headers.filter((data) => {
        return checkData.key === data.key && checkData.checked === true;
      });
      csvHeadersValue = [...csvHeadersValue, ...csvHeaders];
    });
    this.isDisabledDownloadBtn = csvHeadersValue.length > 0 ? false : true;
    this.csvHeadersValue = csvHeadersValue;
  }

  ngAfterViewInit() {
    // for development
    if (!environment.production && this.aRoute.snapshot.queryParams.opened) {
      requestAnimationFrame(() => this.toggleAccordion());
    }
  }

  setTitle() {
    const currentTitle = this.title.getTitle();
    const titleSuffix = ' | ユーザー一覧';
    this.title.setTitle(currentTitle.replace(/(\s\|\s.+)?$/, titleSuffix));
  }

  getMembersTotal() {
    const query = {
      leaved: 'false',
      _limit: -1,
      _start: 0
    };
    this.apiService.getMember(query)
      .subscribe((result: any) => {
        this.dataTotal = result;
        this.membersTotal = result.length;
      });
  }

  search() {
    const search = this.memberSearch.buildSearchQurey();
    search._limit = -1;
    search._start = 0;
    if (!environment.production) {
      console.log(search);
    }
    this.dataSource.searchMembers({
      search,
    });
  }

  toggleAccordion() {
    const accordionHTML: HTMLElement = this.accordion.nativeElement;
    const accordionHeadHTML: HTMLElement = this.accordionHead.nativeElement;
    const headHeight = accordionHeadHTML.offsetHeight;
    const childrenElement = accordionHTML.children;
    if (this.accordionOpened) {
      // close accordion
      this.renderer2.setStyle(accordionHTML, 'height', headHeight + 'px');
    } else {
      let childrenHeight = 0;
      _.forEach(childrenElement, (elem, i) => {
        const virtual = _.some(elem.classList, className => className.match(/^virtual-.+/));
        if (!virtual) {
          childrenHeight += (elem as HTMLElement).offsetHeight;
        }
      });
      // open accordion
      this.renderer2.setStyle(accordionHTML, 'height', 'auto');
    }
    this.accordionOpened = !this.accordionOpened;
  }

  browseFile(mode: string): void {
    const fileInput = mode === 'create' ? this.createFileInput : this.updateFileInput;
    fileInput.nativeElement.value = null;
    fileInput.nativeElement.click();
  }

  openProcessFailDialog(err: any) {
    const html = '<div>更新処理が失敗しました。</div>';
    this.dialog.openConfirmDialog({
      autoFocus: false,
      data: {
        html,
        apply: false
      }
    });
  }

  showErrorForUpdate(err: Error) {
    this.openProcessFailDialog(err);
  }

  generateMembersJSON(mode: string): Promise<void> {
    const data: LodingDialogData = { text: 'CSVデータ読み込み中...' };
    const loadingDialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const fileInput = mode === 'create' ? this.createFileInput : this.updateFileInput;
    const file = _.nth(fileInput.nativeElement.files, 0);
    const factory = this.componentResolver.resolveComponentFactory(UserBatchRegistrationComponent);
    const component = this.userBatchContainer.createComponent(factory);

    if (!(file instanceof File)) {
      return;
    }
    this.CSVService.convertFileToJSON(file)
      .pipe(
        map(list => {
          if (list) {
            const convertedList = this.memberService.convertHeadersFromJPStringToKey(list);
            return component.instance.parseJson(convertedList);
          }
        }),
        // Validate read values.
        switchMap((profiles: any[]) => defer(async () => {
          profiles.forEach(profile => {
            if(!profile.groups && mode === 'update') {
              const target = _.find(this.dataTotal, ['id', profile.memberNumber]);
              if (target && target.groups) {
                profile.groups = target.groups.map(group => group.groupName);
              }
            }
          });
          await component.instance.getMastersOfMember();
          if (this.servicePermissions.group.read) {
            await component.instance.getAllGroups();
          } else {
            await component.instance.getOwnGroups(this.groupList);
          }
          await component.instance.validateMembersJSON(profiles, mode);
          loadingDialog.close();
          return profiles;
        })),
        switchMap(profiles => this.openConfirmationDialog(profiles, mode).afterClosed()
          .pipe(
            filter(result => !!result),
            map(() => profiles.map(prof => component.instance.covertToProfile(prof, mode)))
          )
        )
      )
      .subscribe(async members => {
        const [apply, cancelText] = [false, '確認'];
        let index = 2;
        // tslint:disable-next-line: no-shadowed-variable
        const data: LodingDialogData = { text: `メンバーを${mode === 'create' ? '登録' : '更新'}しています...` };
        const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });
        //check foreign key duplicate for update
        new Promise<void>(async (resolve, reject) => {
          const includeSame = [];
          let html = '';
          await Promise.all(members.map(async (member) => {
            const totalmemberIds = this.dataTotal.filter(data => {
              const sameGp = [];
              data.groups.forEach(d => {
                member.groups.forEach(m => {
                  if (d.id === m) {
                    sameGp.push(d.id)
                  }
                })
              });
              return sameGp.length > 0;
            }).filter(item => {
              if (item.id === member.id) {
                if (item.foreignKey !== member.foreignKey) {
                  return item;
                }
              } else {
                return item;
              }
            });
            const existedKeys = totalmemberIds.filter(mem => mem.id).map(data => data.foreignKey).filter(keys => keys);
            if (existedKeys.includes(member.foreignKey)) {
              includeSame.push(index);
            }
            index++;
          })).then(() => {
            if (mode === 'update' && includeSame.length > 0) {
              dialog.close();
              includeSame.forEach((member) => {
                html = html + `<div>${member}行目：「連携用ID」が重複しています。</div>`;
              })
              this.dialog.openConfirmDialog({
                panelClass: ['mat-dialog-scrollable'],
                autoFocus: false,
                data: { html, apply, cancelText }
              });
              reject('duplicate key');
            } else {
              resolve();
            }
          })
        }).then(async () => {
          switch (mode) {
            case 'create':
              const memberCSVAdd = () => {
                return new Promise((resolve, reject) => {
                  this.registerMembers(members)
                    .subscribe(() => {
                      this.getMembersTotal();
                      resolve(0);
                    }, (err: HttpErrorResponse) => {
                      dialog.close();
                      console.error(err);
                      let html = '';
                      const errData = err.error.message;
                      if (errData && Array.isArray(errData) && errData.length > 0) {
                        errData.forEach(data => {
                          html = html + `<div>${data}<br></div>`;
                        })
                      }
                      else {
                        html = `
                        <div>メンバーを登録できませんでした。</div>
                      `;
                      }
                      this.dialog.openConfirmDialog({
                        panelClass: ['mat-dialog-scrollable'],
                        autoFocus: false,
                        data: { html, apply, cancelText }
                      });
                      reject(err);
                    });
                });
              };
              await memberCSVAdd();
              dialog.close();
              this.dialog.openConfirmDialog({
                data: {
                  apply,
                  cancelText,
                  text: 'メンバー登録が完了しました。',
                }
              });
              break;
            case 'update':
              const chunkSize = 10;
              const memberChunks = chunk(members, chunkSize);
              let errshow = false;
              let errMsg = null;
              const memberUpdate = () => {
                return new Promise((myresolve, myreject) => {
                  const results = [];
                  memberChunks.forEach((member: any, i: number) => {
                    const currentSize = i * chunkSize + member.length;
                    data.text = `メンバーを更新しています... (${currentSize}/${members.length})`;
                    if (!errshow) {
                      member.forEach(async (m: any) => {
                        if (m.member_confidential && m.member_confidential.member_confidential_tag_masters) {
                          m.member_confidential_tag_masters = m.member_confidential.member_confidential_tag_masters;
                        }
                        if (m.member_confidential && m.member_confidential.remarks) {
                          m.remarks = m.member_confidential.remarks;
                        }

                        m = _.omit(m, ['email1', 'image1', 'image2', 'image3', 'image4', 'connection_twitter.followersCount']);
                        this.apiService.updateMember(m.id, m)
                          .subscribe(async result => {
                            results.push(result);
                            this.getMembersTotal();
                            if (results.length === members.length) {
                              myresolve(results);
                            }
                          }, err => {
                            dialog.close();
                            console.error(err);
                            errshow = true;
                            errMsg = err;
                            myreject(err);
                          });
                      });

                    }
                  });
                });
              };

              try {
                await memberUpdate();
              } catch (err) {
                dialog.close();
                errshow = true;
              }
              if (errshow) {
                dialog.close();
                this.showErrorForUpdate(errMsg);
              } else {
                dialog.close();
                this.dialog.openConfirmDialog({
                  data: {
                    apply,
                    cancelText,
                    text: 'メンバー更新が完了しました。',
                  }
                });
              }
              break;
            default:
              break;
          }
        }).catch(err => console.error(err));
      }, err => {
        console.error(err);
        loadingDialog.close();
        this.openInvalidErrorDialog(err);
      });
  }

  registerMembers(json: any[]) {
    return this.apiService.createMemberFromCSV(json, String(this.sendWelcomeMail));
  }

  openConfirmationDialog(profiles: ReadProfile[], mode: string) {
    const dialogRef = this.dialog.openTemplateDialog(this.loadedUserList, {
      data: { profiles, mode },
      autoFocus: false
    });
    return dialogRef;
  }

  openInvalidErrorDialog(err: any) {
    let html = '<div>CSVファイルの以下の入力内容に誤りがあります。</div>';
    if (Array.isArray(err.results)) {
      _.forEach(err.results as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        html += `<div>${message}</div>`;
      });
    } else {
      html += `<br /><div>CSVの形式が正しくありません。</div>`;
    }
    this.dialog.openConfirmDialog({
      panelClass: ['mat-dialog-scrollable'],
      minWidth: '600px',
      maxHeight: '90vh',
      autoFocus: false,
      data: {
        html,
        apply: false
      }
    });
  }

  setJST(birthDate: any) {
    const date = new Date(birthDate);
    // console.log('current : ', date);
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    // japan UTC + 9
    const offset = 9;
    const japanTime = utc + (3600000 * offset);
    const nd = new Date(japanTime);
    // console.log('nihon : ', nd);
    nd.setHours(0);
    nd.setMinutes(0);
    nd.setSeconds(0);
    // console.log('nihon reset : ', nd);
    return nd;
  }

  async downloadMembersCsv() {
    let query = {} as any; // search: [], size: 1000, from: 0
    const memberResult: Member[] = [];
    let isSortWithInstagram = false;
    let isSortWithAiueo = false;
    const [applyText, cancel] = ['OK', false];
    const passwordConfirm: any = await this.passwordConfirmService.passwordConfirm();
    if (passwordConfirm.length > 0) {
      const request$ = body => this.apiService.getMember(body)
        .pipe(
          switchMap((result: Member[]) => {
            if (this.servicePermissions.administrate) {
              return of(result);
            }
            // Has no permissions
            const promises: Promise<Member>[] = [];
            result.forEach(member => promises.push(
              new Promise(async resolve => {
                const address: NewAddress = { postalCode: null, address: null };
                try {
                  assign(member.address = address);
                } catch (err) {
                  console.error(member, err);
                } finally {
                  resolve({ ...member });
                }
              })
            ));
            return from(Promise.all(promises));
          }),
          switchMap(results => {
            memberResult.push(...results);
            return of(memberResult);
          }),
        );
      const dialog = this.dialog.openLoadingDialog({
        data: { text: 'CSVを作成しています...' },
        disableClose: true,
      });
      const headers = this.memberService.getHeaders();
      const { administrate } = this.servicePermissions;

      if (administrate) {
        const pos = findIndex(headers, ['key', 'profile']);
        const addressHeaders = [
          { key: 'postalCode', value: '郵便番号' },
          { key: 'address', value: '住所' },
        ];
        headers.splice(pos, 0, ...addressHeaders);
      }
      // Assign group list before query building.
      this.memberService.setGroups(this.groupList);
      this.memberService.subscribeConfidentialTag();
      query = this.memberSearch.buildSearchQurey();
      query._limit = -1;
      query._start = 0;
      if (query._sort && query._sort === 'connection_instagrams_followersCount:DESC') {
        delete query._sort;
        isSortWithInstagram = true;
      }
      if (query._sort && query._sort === 'fullNameKana:ASC') {
        delete query._sort;
        isSortWithAiueo = true;
      }
      request$(query).subscribe((member: any) => {
        // JSON、ヘッダーを渡してメンバー一覧のCSVを作れる形式のJSONに整形する
        if (isSortWithInstagram || isSortWithAiueo) {
          member = this.dataSource.totalMember;
        }
        member.forEach((mem: any) => {
          // set JST
          if (mem.birthDay) {
            mem.birthDay = this.setJST(mem.birthDay);
          }
          mem.children = _.map(mem.children, child => {
            if (child.birthDay) {
              child.birthDay = this.setJST(child.birthDay);
            } else {
              child.birthDay = null;
            }
            return child;
          });
          mem.memberNumber = mem.id;
        });
        const formattedJson = this.memberService.formatJSONForMemberCsv(member, this.csvHeadersValue);
        const fileName = `member-list_${moment().format('YYYY-MM-DD')}.csv`;
        this.CSVService.downloadCSV(formattedJson, fileName);
        dialog.close();
      }, (err: any) => {
        console.error(err);
        dialog.afterClosed().subscribe(() => {
          const data: ConfirmationDialogData = {
            text: 'CSVが作成出来ませんでした。',
            apply: false,
            cancelText: '確認'
          };
          this.dialog.openConfirmDialog({ data });
        });
        dialog.close();
      });
    } else {
      this.dialog.openConfirmDialog({
        data: {
          applyText,
          cancel,
          text: 'パスワードが一致しません。',
        }
      });
    }
  }

  downloadTemplateCSV() {
    const templateData = [];
    this.headers.forEach(csvHead => {
      const templateHeader = {};
      templateHeader[csvHead] = '';
      templateData.push(templateHeader);
    });
    const fileName = `member-update-template.csv`;
    this.CSVService.downloadCSV(templateData, fileName);
  }


  unionArray(list: any[], item) {
    if (findIndex(list, o => isEqual(o, item)) >= 0) {
      return;
    }
    list.push(item);
  }

  arrayRemove(list: any[], item) {
    const index = findIndex(list, o => isEqual(o, item));
    if (index === -1) {
      return;
    }
    list.splice(index, 1);
  }

  openInvitationDailog() {
    const dialog = this.dialog.openComponentDialog(MemberInviteDialogComponent, {
      autoFocus: false,
      panelClass: ['invitation-dialog']
    });
  }

  async goToUserDetail(userId: any) {
    const [applyText, cancel] = ['OK', false];
    const passwordEnterDate = sessionStorage.getItem('PasswordConfirmDate');
    if (passwordEnterDate) {
      this.router.navigate([this.path.user.detailBase, userId]);
    } else {
      const passwordConfirm: any = await this.passwordConfirmService.passwordConfirm();
      if (passwordConfirm.length > 0) {
        sessionStorage.setItem('PasswordConfirmDate', Date.now().toString());
        this.router.navigate([this.path.user.detailBase, userId]);
      } else {
        this.dialog.openConfirmDialog({
          data: {
            applyText,
            cancel,
            text: 'パスワードが一致しません。',
          }
        });
      }
    }
  }
}
