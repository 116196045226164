import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface PasswordConfirmgData {
  password?: string;
  html?: string;
  text?: string;
  apply?: boolean;
  cancel?: boolean;
  applyText?: string;
  cancelText?: string;
  applyButtonColor?: ThemePalette;
  cancelButtonColor?: ThemePalette;
}

@Component({
  selector: 'lu-password-confirm-dialog',
  templateUrl: './password-confirm-dialog.component.html',
  styleUrls: ['./password-confirm-dialog.component.scss']
})
export class PasswordConfirmDialogComponent implements OnInit {
  public passwordForm = new FormGroup({
    password: new FormControl(null, [
      Validators.required,
    ])
  });

  constructor(
    public dialogRef: MatDialogRef<PasswordConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordConfirmgData
  ) {
    if (typeof this.data !== 'object') {
      this.data = {};
    }
    if (typeof this.data.apply !== 'boolean') {
      this.data.apply = true;
    }
    if (typeof this.data.cancel !== 'boolean') {
      this.data.cancel = true;
    }
  }

  ngOnInit() {
  }

}
