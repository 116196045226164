import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface TextDialogComponentData {
  text: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss']
})
export class TextDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextDialogComponentData,
  ) {}

  ngOnInit() {}
}
