/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Entry {
    id: string;
    entriedAt: Date;
    offeredAt?: Date;
    offeredBy?: number;
    approvedAt?: Date;
    canceledAt?: Date;
    decidedAt?: Date;
    decidedBy?: number;
    reportedAt?: Date;
    completedAt?: Date;
    completedBy?: number;
    status: Entry.StatusEnum;
    member?: object;
    project?: object;
    reward?: object;
    candidate?: object;
    conflict?: boolean;
}
// tslint:disable-next-line: no-namespace
export namespace Entry {
    export type StatusEnum = 'entried' | 'offered' | 'approved' | 'decided' | 'canceled' | 'completed';
    export const StatusEnum = {
        Entried: 'entried' as StatusEnum,
        Offered: 'offered' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Decided: 'decided' as StatusEnum,
        Canceled: 'canceled' as StatusEnum,
        Completed: 'completed' as StatusEnum
    };
}


