import { Component, OnInit, Input, OnDestroy, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatSelectChange, MatDialogRef, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFireAuth } from '@angular/fire/auth';

import { Subject } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Entry, Member, Project, Report } from '@lu/models';
import { DialogService } from '@lu/services/dialog.service';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { OrderReportDialogComponent } from '@lu/components/order-report-dialog/order-report-dialog.component';
import { LoadingDialogComponent } from '@lu/components/loading-dialog/loading-dialog.component';

// api service
import { MatchingService } from '@lu/services/matching.service';
import { CsvdlItemsSelectableComponent } from '../csvdl-items-selectable/csvdl-items-selectable.component';
import { MemberService } from '@lu/services/member.service';
import { isArray, isNil, update } from 'lodash';
import * as moment from 'moment';
import { PasswordConfirmService } from '@lu/services/password-confirm.service';
type EntryMember = { id: number, report: Report } &
  Entry &
  Pick<Member, 'id' | 'fullName' | 'displayName'>;
enum ReportStatusEnum {
  UnReported = 'unReported',
  Reported = 'reported',
  Rejected = 'rejected',
  Approved = 'approved',
}

@Component({
  selector: 'lu-order-entry-users',
  templateUrl: './order-entry-users.component.html',
  styleUrls: ['./order-entry-users.component.scss']
})
export class OrderEntryUsersComponent implements OnInit, OnDestroy {
  @ViewChild('csvData', { static: true }) public csvData: CsvdlItemsSelectableComponent;
  @Input() orderId: string;
  @Input() allowDuplicate: boolean;
  public order: Project;
  public entries: Array<Entry>;
  public entryMembers: EntryMember[];
  public entryMembersList = new MatTableDataSource<Element>();
  public entryMembersForm = new FormArray([]);
  public batchStatus = new FormControl(null);
  public filterStatus = new FormControl(null);
  public entryMemberSelection = new SelectionModel<EntryMember>(true, []);
  public statusList = [
    {
      label: 'エントリー中',
      value: Entry.StatusEnum.Entried,
    },
    {
      label: '内定',
      value: Entry.StatusEnum.Offered,
    },
    {
      label: '仮決定',
      value: Entry.StatusEnum.Approved,
    },
    {
      label: '本決定',
      value: Entry.StatusEnum.Decided,
    },
    {
      label: '完了',
      value: Entry.StatusEnum.Completed,
    },
    {
      label: '見送り',
      value: Entry.StatusEnum.Canceled,
    },
  ];
  public reportStatusEnum = ReportStatusEnum;
  public readonly columnToDisplay = ['check', 'id', 'name', 'entrySchedule',  'statusChange', 'entryConflict' , 'report'];
  private onDestroy$ = new Subject();
  private getEntryMembers$ = new Subject();
  private admin: number;
  public csvHeadersValue: any;
  public isDisabledDownloadBtn: any = false;
  public isEntry = true;
  public conflict: boolean;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private afAuth: AngularFireAuth,
    private dialog: DialogService,
    private CSVService: XLXSCSVService,
    private apiService: MatchingService,
    private memberService: MemberService,
    private passwordConfirmService: PasswordConfirmService
  ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.getOrderDetail();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  async getCurrentUser() {
    const { uid } = this.afAuth.auth.currentUser;
    const admin = () => {
      return new Promise<number>(resolver => {
        this.apiService.getAdminUser({ uid })
          .subscribe((doc: any) => {
            if (doc.length > 0) {
              resolver(doc[0].id);
            }
          }, err => console.error(err));
      });
    };
    this.admin = await admin();
  }

  getReportStatus(report: Report): ReportStatusEnum {
    if (_.isNil(report)) {
      return this.reportStatusEnum.UnReported;
    }
    if (report.approved) {
      return this.reportStatusEnum.Approved;
    }
    if (report.read && report.rejected) {
      return this.reportStatusEnum.Rejected;
    }
    return this.reportStatusEnum.Reported; // reported and read or not
  }

  filterEntryUserList(event: MatSelectChange) {
    this.entryMemberSelection.clear();
    this.batchStatus.patchValue(event.value);
    this.getEntryMembers(event.value);
  }

  getOrderDetail() {
    this.apiService.getProject({ id: this.orderId }).subscribe(
      result => {
        this.order = result[0];
        this.getEntryMembers();
      }, error => {
        console.error('error in get project in order-entry ====> ', error);
      }
    );
  }

  async getEntryMembers(status: Entry.StatusEnum = null) {
    this.getEntryMembers$.next();
    this.entryMembers = void 0;
    this.entryMembersList = void 0;
    const allData = [];
    if (this.order.entries.length > 0) {
      const entryIds = this.order.entries.map(list => _.get(list, 'id'));
      this.apiService.getEntries({ id_in: entryIds, status })
        .subscribe(entry => {
          if (entry.length > 0) {
            entry.forEach((entryMember: any) => {
              if (this.orderId === entryMember.project.id) {
                this.addEntryMemberControl(_.cloneDeep(entryMember));
              }
            });
            entry.forEach((entryItem: any) => {
              if (this.orderId === entryItem.project.id && entryItem.member && !isNil(entryItem.member)) {
                this.apiService.getEachMember(entryItem.member.id, {})
                  .subscribe((member: Member) => {
                    const memberData = _.omit(_.cloneDeep(member), ['id']);
                    const params = `?member.id=${entryItem.member.id}&project.id=${entryItem.project.id}`;
                    this.apiService.getReportWithPara(params)
                      .subscribe(doc => {
                        const reportData = {
                          report: doc[0]
                        };
                        allData.push({ ...entryItem, ...memberData, ...reportData });
                        this.entryMembersList = new MatTableDataSource(allData);
                        this.entryMembers = allData;
                      });
                  });
              }
            });
            setTimeout(() => {
              if (this.entryMembers === undefined) {
                this.entryMembersList = new MatTableDataSource([]);
                this.entryMembers = [];
              }
            }, 1000);
          } else {
            this.entryMembersList = new MatTableDataSource([]);
            this.entryMembers = [];
          }
        }, err => console.error(err));
    } else {
      this.entryMembersList = new MatTableDataSource([]);
      this.entryMembers = [];
    }
  }

  addEntryMemberControl(entry: EntryMember) {
    const ctrl = new FormGroup({
      _id: new FormControl(entry.id),
      status: new FormControl(entry.status),
      conflict: new FormControl(entry.conflict),
    });
    this.entryMembersForm.push(ctrl);
  }

  getEntryStatusControl(entryId: number) {
    const ctrl = _.find(this.entryMembersForm.controls, ['value._id', entryId]);
    return ctrl.get('status');
  }

  confirmChangeEntryStatus(event: MatSelectChange, entryMember: EntryMember) {
    this.openConfirmationDialog(entryMember, event.value)
      .afterClosed()
      .pipe(filter(result => !result))
      .subscribe(() => {
        const ctrl = this.getEntryStatusControl(entryMember.id);
        ctrl.patchValue(entryMember.status);
        ctrl.markAsPristine();
      });
  }

  cancelEntry() {
    this.dialog.openOrderEntryCancelDialog({});
  }

  openConfirmationDialog(entryMember: EntryMember, afterStatus: Entry.StatusEnum) {
    const memberEnter: any = entryMember;
    const beforeStatusLabel = _.find(this.statusList, ['value', entryMember.status]).label;
    const afterStatusLabel = _.find(this.statusList, ['value', afterStatus]).label;
    const cancelState = _.find(this.statusList, ['value', Entry.StatusEnum.Canceled]).label;
    const decidedState = _.find(this.statusList, ['value', Entry.StatusEnum.Decided]).label;

    if (afterStatus === Entry.StatusEnum.Decided && this.allowDuplicate === false) {
        this.conflict = true;
    } else {
      this.conflict = false;
    }
    let dialogRef;
    if (afterStatus === Entry.StatusEnum.Canceled && afterStatusLabel === cancelState) {
      dialogRef = this.dialog.openOrderEntryCancelDialog({
        data: {
          sent: true,
          memberIds: [memberEnter.member.id],
          projectId: this.orderId
        }
      });
    } else {
      dialogRef = this.dialog.openConfirmDialog({
        data: {
          text: `ステータスを${beforeStatusLabel}から${afterStatusLabel}に変更します。よろしいですか？`,
        }
      });
    }
    dialogRef.afterClosed()
      .pipe(
        filter(result => !!result),
        switchMap(() => this.changeEntryStatus(entryMember, afterStatus)),
        // Update cache
        tap(() => {
          if (this.conflict === true && entryMember.conflict === true) {
            afterStatus = Entry.StatusEnum.Canceled;
          }
          const member = _.find(this.entryMembers, ['id', entryMember.id]);
          const ctrl = _.find(this.entryMembersForm.controls, ['value._id', entryMember.id]);
          member.status = afterStatus;
          ctrl.patchValue({ status: afterStatus, conflict: this.conflict });
        })
      )
      .subscribe(() => {
        this.dialog.openConfirmDialog({
          data: {
            text: 'ユーザーのエントリーステータス変更が完了しました。',
            cancel: false,
            applyText: '確認'
          }
        });
      }, err => {
        console.error(err);
        const ctrl = this.getEntryStatusControl(entryMember.id);
        ctrl.patchValue(entryMember.status);
        ctrl.markAsPristine();
        this.dialog.openConfirmDialog({
          data: {
            text: 'ユーザーのエントリーステータス変更を完了できませんでした。',
            apply: false,
            cancelText: '確認'
          }
        });
      });
    return dialogRef;
  }

  async checkForDecided(entryMember, status) {
    return new Promise(async (filterResolve) => {
      const brand: any = this.order.brand_master ? this.order.brand_master : null;
      const entryMem: any = entryMember.member ? entryMember.member : null;
      const filteredpj = [];
      let trueCheck;
      const routePj = {
        allowDuplicatedEntry: false,
        _limit : -1
      };
      const getpjlist = () => {
        return new Promise((pjResolve) => {
          this.apiService.getProject(routePj)
            .subscribe(pjs => {
              _.filter(pjs, ['candidates.member', entryMem.id]);
              pjResolve(pjs);
            });
        });
      };
      const pjlist: any = await getpjlist();
      const pjIds = pjlist.map(ma => ma.id);

      const routeData = {
        member: entryMem.id,
        _limit: -1
      };
      const getentrylist = () => {
        return new Promise((pjResolve) => {
          this.apiService.getEntries(routeData)
            .subscribe(entries => {
              const filterentry = _.forEach(entries , (et: any) => {
                if (et.project && _.includes(pjIds, et.project.id)) {
                  return et;
                }
              });
              _.remove(filterentry, ['id', entryMember.id]);
              pjResolve(filterentry);
            });
        });
      };

      const allentrylist: any = await getentrylist();
      const entrylist: any = allentrylist.filter(o1 => o1.status === 'completed' || o1.status === 'decided' );
      const entrypj: any =  pjlist.filter(o1 => allentrylist.some(o2 => {
        if (o1 && o2 && o2.project &&  o1.id === o2.project.id) {
          return true;
        } else {
          return false;
        }
      }));
      const exists = _.find(entrypj, ['id', this.order.id]);
      if (exists) {
        _.remove(entrypj, ['id', this.order.id]);
      }
      _.forEach(entrypj, pj => {
        const pdmaster = pj.product_masters ? pj.product_masters.map(ma => ma.id) : [];
        const members = pj.candidates ? pj.candidates.map(ma => ma.member ? ma.member : null) : [];
        const products: any = this.order.product_masters ? this.order.product_masters : [];
        const pdid = products.map(ma => ma.id);
        const candidates: any = this.order.candidates ? this.order.candidates : [];
        const memid = candidates.map(ma => ma.member ? ma.member : null);
        const result1 =  pdmaster.filter(o1 => pdid.some(o2 => o1 === o2));
        const result2 = members.filter(o1 => memid.some(o2 => o1 === o2)) ;
        const bdmaster = pj.brand_master ? pj.brand_master : null;
        const brandresult = (bdmaster && brand) ? (bdmaster.id === brand.id ? bdmaster : null) : null;

        const startevent = new Date(pj.eventStartAt);
        startevent.setHours(0, 0, 0, 0);
        const endevent = new Date(pj.eventEndAt);
        endevent.setHours(0, 0, 0, 0);
        const currentStart = new Date(this.order.eventStartAt);
        currentStart.setHours(0, 0, 0, 0);
        const currentEnd = new Date(this.order.eventEndAt);
        currentEnd.setHours(0, 0, 0, 0);

        const samestart = moment(endevent).isSame(currentEnd);
        const startbefore = moment(currentStart).isBefore(startevent);
        const startafter = moment(currentStart).isAfter(startevent);
        const startbetween = currentStart <= endevent && currentStart >= startevent;

        const sameend = moment(endevent).isSame(currentEnd);
        const endbefore = moment(currentEnd).isBefore(endevent);
        const endafter = moment(currentEnd).isAfter(endevent);
        const endbetween = currentEnd <= endevent && currentEnd >= startevent;
        const filtered = (startbefore && endbetween) || (samestart && sameend) ||
                          (startbetween && endafter) || (startbefore && endafter) || (startbetween && endbetween);

        trueCheck = (result1.length > 0 || brandresult !== null )  && result2.length > 0 && filtered === true;
        if (trueCheck === true && allentrylist.length > 0) {
          filteredpj.push(pj);
        }
      });
      const filterentrypj: any =  entrylist.filter(ent => filteredpj.some(entpj => {
        if (ent && entpj && ent.project &&  ent.project.id === entpj.id) {
          return true;
        } else {
          return false;
        }
      }));
      const leftentry: any =  allentrylist.filter(o1 => filteredpj.some(o2 => {
        if (o1 && o2 && o1.project &&  o1.project.id === o2.id) {
          return true;
        } else {
          return false;
        }
      }));
      const exist = _.find(leftentry, entry => {
        return entry.status === Entry.StatusEnum.Decided || entry.status === Entry.StatusEnum.Completed;
      });
      if (exist) {
        _.remove(leftentry,  (entry: any) => {
          return entry.status === Entry.StatusEnum.Decided || entry.status === Entry.StatusEnum.Completed;
        });
      }
      if (status === Entry.StatusEnum.Decided && filteredpj.length > 0) {
        _.forEach(leftentry, entry => {
          let updateEntry: Entry;
          entry.conflict = true;
          updateEntry = this.getUpdateEntry(entry, Entry.StatusEnum.Canceled, entry.report);
          this.apiService.updateEntry(entry.id, updateEntry).subscribe();
        });
      }
      filterResolve(true);
    });
  }

  async changeEntryStatus(entryMember: EntryMember, currentStatus: Entry.StatusEnum) {
    const id = entryMember.id;
    const dialog = this.dialog.openLoadingDialog({ data: { text: 'エントリーを更新しています...' }, disableClose: true });
    const state = currentStatus;
    const report = entryMember.report;

    const entryData = {
      entriedAt: entryMember.entriedAt,
      offeredAt: entryMember.offeredAt,
      offeredBy: entryMember.offeredBy,
      approvedAt: entryMember.approvedAt,
      canceledAt: entryMember.canceledAt,
      decidedAt: entryMember.decidedAt,
      decidedBy: entryMember.decidedBy,
      reportedAt: entryMember.reportedAt,
      completedAt: entryMember.completedAt,
      completedBy: entryMember.completedBy,
      status: entryMember.status,
      member: entryMember.member,
      project: entryMember.project,
      reward: entryMember.reward,
      candidate: entryMember.candidate,
      conflict: entryMember.conflict
    } as Entry;


    if (this.conflict === true) {
      const ppj: any = await this.checkForDecided(entryMember, currentStatus);
      if (ppj === true) {
        entryMember.status = currentStatus;
        entryData.conflict = false;
        entryMember.conflict = false;
      }
    } else {
      entryMember.status = currentStatus;
      entryMember.conflict = false;
      entryData.conflict = false;
    }

    const currentEntry: Entry = entryData;
    const updateEntry = this.getUpdateEntry(currentEntry, state, report);
    this.apiService.updateEntry(id, updateEntry).subscribe(
      editData => {
        if (editData) {
          dialog.close();
          return true;
        }
      }, error => {
        console.error(error);
        dialog.close();
      }
    );
  }

  getUpdateEntry(currentEntry: Entry, currentStatus: Entry.StatusEnum, report: any) {
    // apply stauts
    const updatingEntry = { ...currentEntry };
    currentEntry.status = currentStatus;
    currentEntry.entriedAt = null;
    currentEntry.offeredAt = null;
    currentEntry.offeredBy = null;
    currentEntry.approvedAt = null;
    currentEntry.decidedAt = null;
    currentEntry.decidedBy = null;
    currentEntry.reportedAt = null;
    currentEntry.completedAt = null;
    currentEntry.completedBy = null;
    currentEntry.canceledAt = null;
    switch (currentStatus) {
      case Entry.StatusEnum.Entried:
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        break;
      case Entry.StatusEnum.Offered:
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        currentEntry.offeredAt = new Date().toISOString() as any;
        currentEntry.offeredBy = this.admin;
        break;
      case Entry.StatusEnum.Approved:
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        currentEntry.approvedAt = new Date().toISOString() as any;
        currentEntry.offeredAt = (currentEntry.offeredAt) ? currentEntry.offeredAt : new Date().toISOString() as any;
        currentEntry.offeredBy = this.admin;
        break;
      case Entry.StatusEnum.Decided:
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        currentEntry.decidedAt = new Date().toISOString() as any;
        currentEntry.decidedBy = this.admin;
        currentEntry.approvedAt = currentEntry.approvedAt ? currentEntry.approvedAt : new Date().toISOString() as any;
        currentEntry.offeredAt = (currentEntry.offeredAt) ? currentEntry.offeredAt : new Date().toISOString() as any;
        currentEntry.offeredBy = this.admin;
        break;
      case Entry.StatusEnum.Completed:
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        currentEntry.completedAt = new Date().toISOString() as any;
        currentEntry.completedBy = this.admin;
        currentEntry.decidedAt = (currentEntry.decidedAt) ? currentEntry.decidedAt : new Date().toISOString() as any;
        currentEntry.decidedBy = this.admin;
        currentEntry.offeredAt = (currentEntry.offeredAt) ? currentEntry.offeredAt : new Date().toISOString() as any;
        currentEntry.offeredBy = this.admin;
        currentEntry.approvedAt = (currentEntry.approvedAt) ? currentEntry.approvedAt : updatingEntry.approvedAt;
        currentEntry.reportedAt = (updatingEntry.reportedAt) ? updatingEntry.reportedAt : null;
        break;
      case Entry.StatusEnum.Canceled:
        this.deleteReport(report);
        currentEntry.entriedAt = (currentEntry.entriedAt) ? currentEntry.entriedAt : new Date().toISOString() as any;
        currentEntry.offeredAt = currentEntry.offeredAt;
        currentEntry.offeredBy = currentEntry.offeredBy;
        currentEntry.approvedAt = currentEntry.approvedAt;
        currentEntry.decidedAt = currentEntry.decidedAt;
        currentEntry.decidedBy = currentEntry.decidedBy;
        currentEntry.reportedAt = currentEntry.reportedAt;
        currentEntry.completedAt = currentEntry.completedAt;
        currentEntry.completedBy = currentEntry.completedBy;
        currentEntry.canceledAt = new Date().toISOString() as any;
        break;
    }
    return currentEntry;
  }

  deleteReport(report: any) {
    if (!_.isNil(report)) {
      this.apiService.deleteReport(report.id).subscribe(() => { }, err => console.error(err));
    }
  }

  changeCheckboxData() {
    const checkbox = [...this.csvData.basicInfoList, ...this.csvData.basicInfoListForEntry
      , ...this.csvData.personalInfoList, ...this.csvData.profileList
      , ...this.csvData.otherList];
    const headers = this.memberService.getHeaders();
    let csvHeaders = [];
    let csvHeadersValue = [];
    checkbox.map(checkData => {
      csvHeaders = headers.filter((data) => {
        return checkData.key.indexOf(data.key) > -1 && checkData.checked === true;
      });
      csvHeadersValue = [...csvHeadersValue, ...csvHeaders];
    });
    this.isDisabledDownloadBtn = csvHeadersValue.length > 0 ? false : true;
    this.csvHeadersValue = csvHeadersValue;
  }

  async downloadMembers() {
    this.csvHeadersValue = [...this.csvHeadersValue];
    const [applyText, cancel] = ['OK', false];
    this.entryMembers.forEach((entries: any) => {
      entries.memberNumber = entries.member.id;
    });
    const formattedJson = this.memberService.formatJSONForMemberCsv(this.entryMembers, this.csvHeadersValue);
    const fileName = `${this.order.projectName}_entry_member.csv`;
    const passwordConfirm: any = await this.passwordConfirmService.passwordConfirm();
    if (passwordConfirm.length > 0) {
      this.CSVService.downloadCSV(formattedJson, fileName);
    } else {
      this.dialog.openConfirmDialog({
        data: {
          applyText,
          cancel,
          text: 'パスワードが一致しません。',
        }
      });
    }
  }

  openReportDetailDialogAndUpdate(entryMember: EntryMember, isDisableClose: boolean = true) {
    const data = {
      entryMember: [entryMember],
      isDisable: !isDisableClose
    };
    const orderReportDialogRef = this.dialog.openComponentDialog(OrderReportDialogComponent, {
      data,
      width: '75%',
      maxHeight: '90vh',
      disableClose: isDisableClose,
      autoFocus: false,
    });
    let loadingDialogRef: MatDialogRef<LoadingDialogComponent, any>;
    orderReportDialogRef
      .afterClosed()
      .pipe(
        filter(result => !!result),
        switchMap((result: Report) => {
          loadingDialogRef = this.dialog.openLoadingDialog({
            data: {
              text: '完了報告の確認結果を送信しています...'
            },
            disableClose: true
          });
          if (result.approved) {
            this.apiService.updateEntry(entryMember.id, {
              status: Entry.StatusEnum.Completed,
              completedAt: new Date().toISOString(),
            }).subscribe();
            entryMember.report.approved = true;
            entryMember.status = Entry.StatusEnum.Completed;
            const ctrl = this.getEntryStatusControl(entryMember.id);
            ctrl.patchValue(entryMember.status);
          } else {
            entryMember.report.read = true;
            entryMember.report.rejected = true;
          }
          return new Promise<void>((resolve) => {
            this.apiService.updateReport(entryMember.report.id, result).subscribe();
            resolve();
          });
        }),
        delay(1500),
      )
      .subscribe(() => {
        loadingDialogRef.afterClosed().subscribe(() => {
          this.dialog.openConfirmDialog({
            data: {
              text: '完了報告の確認結果を送信しました。',
              cancel: false,
              applyText: '確認'
            }
          });
        });
        loadingDialogRef.close();
      }, err => {
        console.error(err);
        loadingDialogRef.afterClosed().subscribe(() => {
          this.dialog.openConfirmDialog({
            data: {
              text: '完了報告の確認結果を送信出来ませんでした。',
              cancel: false,
              applyText: '確認'
            }
          });
        });
        loadingDialogRef.close();
      });
  }

  confirmChangeAllEntryStatus(event: MatSelectChange) {
    if (!event.value) {
      return;
    }
    const beforeStatus = _.find(this.statusList, ['value', this.selectedBatchStatus]);
    const afterStatus = _.find(this.statusList, ['value', event.value]);
    let dialogRef;
    if (afterStatus.value === Entry.StatusEnum.Canceled && !this.entryMemberSelection.isEmpty()) {
      const { selected } = this.entryMemberSelection;
      const ids = selected.map((s: any) => s.member.id);
      dialogRef = this.dialog.openOrderEntryCancelDialog({
        data: {
          sent: true,
          memberIds: ids,
          projectId: this.orderId
        }
      });
    } else {
      dialogRef = this.dialog.openConfirmDialog({
        data: {
          text: beforeStatus ?
            `選択したメンバーのステータスを${beforeStatus.label}から${afterStatus.label}に変更します。よろしいですか？` :
            `選択したメンバーのステータスを${afterStatus.label}に変更します。よろしいですか？`,
        }
      });
    }
    dialogRef.afterClosed()
      .pipe(
        tap(result => {
          if (!result) {
            this.batchStatus.patchValue(this.selectedBatchStatus);
          }
        }),
        filter(result => !!result)
      )
      .subscribe(() => {
        this.updateSelectedEntryStatus(event.value);
      });
  }

  async updateSelectedEntryStatus(updateStatus: Entry.StatusEnum) {
    if (this.entryMemberSelection.isEmpty()) {
      return;
    }

    const loadingDialog = this.dialog.openLoadingDialog({
      disableClose: true,
      data: { text: 'エントリーを更新しています...' },
    });
    const { selected } = this.entryMemberSelection;
    try {
      selected.forEach(async entry => {
        const member = _.find(this.entryMembers, ['id', entry.id]);
        const ctrl = _.find(this.entryMembersForm.controls, ['value._id', entry.id]);
        const report = entry.report;
        member.status = updateStatus;
        const status = updateStatus;
        ctrl.patchValue({ status: updateStatus });
        const entryData = {
          entriedAt: entry.entriedAt,
          offeredAt: entry.offeredAt,
          offeredBy: entry.offeredBy,
          approvedAt: entry.approvedAt,
          canceledAt: entry.canceledAt,
          decidedAt: entry.decidedAt,
          decidedBy: entry.decidedBy,
          reportedAt: entry.reportedAt,
          completedAt: entry.completedAt,
          completedBy: entry.completedBy,
          status: entry.status,
          member: entry.member,
          project: entry.project,
          reward: entry.reward,
          candidate: entry.candidate
        } as Entry;

        if (updateStatus === Entry.StatusEnum.Decided && this.allowDuplicate === false) {
          this.conflict = true;
        } else {
          this.conflict = false;
        }
        if (this.conflict === true) {
          const ppj: any = await this.checkForDecided(entry, updateStatus);
          if (ppj === true) {
            updateStatus = status;
            entry.status = updateStatus;
            entryData.status = updateStatus;
            entryData.conflict = false;
            entry.conflict = false;
          }
        } else {
          updateStatus = status;
          entry.status = updateStatus;
          entryData.status = updateStatus;
          entryData.conflict = false;
          entry.conflict = false;
        }
        const currentEntry: Entry = entryData;
        let updateEntry: Entry;
        if (updateStatus === Entry.StatusEnum.Canceled) {
          updateEntry = this.getUpdateEntry(currentEntry, updateStatus, report);
        } else {
          updateEntry = this.getUpdateEntry(currentEntry, updateStatus, null);
        }
        this.apiService.updateEntry(entry.id, updateEntry).subscribe(updatedEntry => {
          if (updatedEntry) {
            return true;
          }
        }, err => console.error(err));
      });
      loadingDialog.afterClosed().subscribe(() => {
        this.dialog.openConfirmDialog({
          data: {
            text: 'ユーザーのエントリーステータス変更が完了しました。',
            cancel: false,
            applyText: '確認'
          }
        });
      });
      loadingDialog.close();
      this.entryMemberSelection.clear();
    } catch (err) {
      console.error('something was wrong in selected entry update ', err);
      this.batchStatus.patchValue(this.selectedBatchStatus);
      loadingDialog.afterClosed().subscribe(() => {
        this.dialog.openConfirmDialog({
          data: {
            text: 'ユーザーのエントリーステータス変更を完了できませんでした。',
            apply: false,
            cancelText: '確認'
          }
        });
      });
      loadingDialog.close();
    }
  }

  isAllUserSelected() {
    const numSelected = this.entryMemberSelection.selected.length;
    const numRows = this.entryMembers.length;
    return this.entryMemberSelection.hasValue() && numSelected === numRows;
  }

  masterToggle() {
    this.isAllUserSelected() ?
      this.entryMemberSelection.clear() :
      this.entryMembers.forEach((entryMember: EntryMember) =>
        this.entryMemberSelection.select(entryMember));
  }

  get canBatch() {
    return this.filterStatus.value && this.entryMemberSelection.hasValue();
  }

  get selectedBatchStatus(): Entry.StatusEnum | null {
    const list = this.entryMemberSelection.selected;
    // EntryMember, Entries.StatusEnum | null
    return _.reduce(list, (prev, current) => {
      if (!prev) {
        return current.status;
      }
      return current.status === prev ? prev : null;
    }, null);
  }
}
