import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LoadingDialogComponent, LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '@lu/components/confirmation-dialog/confirmation-dialog.component';
import {
  PasswordConfirmDialogComponent,
  PasswordConfirmgData
} from '@lu/components/password-confirm-dialog/password-confirm-dialog.component';
import {
  ConfirmationInputDialogComponent,
  ConfirmationInputDialogData
} from '@lu/components/confirmation-input-dialog/confirmation-input-dialog.component';
import { EntryCancelDialogComponent } from '@lu/components/entry-cancel-dialog/entry-cancel-dialog.component';
import { TextDialogComponent, TextDialogComponentData } from '@lu/components/text-dialog/text-dialog.component';
import { EmailUpdatingData, MemberEmailUpdateDialogComponent } from '@lu/components/member-email-update-dialog/member-email-update-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  closeAll() {
    this.dialog.closeAll();
    return this.dialog.afterAllClosed;
  }

  openLoadingDialog(config: MatDialogConfig<LodingDialogData>) {
    return this.dialog.open<LoadingDialogComponent, LodingDialogData>(LoadingDialogComponent, config);
  }

  openConfirmDialog(config: MatDialogConfig<ConfirmationDialogData>) {
    return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData>(ConfirmationDialogComponent, config);
  }

  openConfirmInputDialog(config: MatDialogConfig<ConfirmationInputDialogData>) {
    return this.dialog.open<ConfirmationInputDialogComponent, ConfirmationInputDialogData>(ConfirmationInputDialogComponent, config);
  }

  openTemplateDialog(template: TemplateRef<any>, config: MatDialogConfig<any>) {
    return this.dialog.open<TemplateRef<any>, any>(template, config);
  }

  /**
   * Before using this dialog, add DialogService to providers, because resolve DI tree shaking.
   */
  openComponentDialog(component: any, config: MatDialogConfig<any>) {
    return this.dialog.open<any, any>(component, config);
  }

  openOrderEntryCancelDialog(config: MatDialogConfig<any>) {
    return this.dialog.open<EntryCancelDialogComponent, any>(EntryCancelDialogComponent, config);
  }

  openPasswordConfirmDialog(config: MatDialogConfig<any> ) {
    return this.dialog.open<PasswordConfirmDialogComponent, PasswordConfirmgData>(PasswordConfirmDialogComponent, config);
  }

  openMemberEmailUpdateDialog(config: MatDialogConfig<any> ) {
    return this.dialog.open<MemberEmailUpdateDialogComponent, EmailUpdatingData>(MemberEmailUpdateDialogComponent, config);
  }

  openTextDialog(config: MatDialogConfig<TextDialogComponentData> = {}) {
    const dialog = this.dialog.open<TextDialogComponent, TextDialogComponentData>(
      TextDialogComponent,
      {...config, maxHeight: '90vh'}
    );
    return dialog;
  }
}
