import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';

import { cloneDeep, isNil, forEach, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Child,
  Group,
  IndustryMaster,
  JobMaster,
  MemberConfidential,
  MemberConfidentialTagMaster,
  MemberStatusMaster,
  OccupationMaster,
  TagMaster,
} from '@lu/models';
import { MatchingService } from './matching.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  public uid: string;
  private groups: Array<Group>;
  private occupationList: Array<OccupationMaster>;
  private industryList: Array<IndustryMaster>;
  private jobList: Array<JobMaster>;
  private memberSegmentList: Array<MemberStatusMaster>;
  private memberTagList: Array<TagMaster>;
  private memberConfidentialTagList: Array<any>;
  private onDestroy$ = new Subject();
  private headers = [
    { key: 'memberNumber', value: 'ID' },
    { key: 'foreignKey', value: '連携用アカウント' },
    { key: 'fullName', value: '氏名' },
    { key: 'fullNameKana', value: '氏名(ふりがな)' },
    { key: 'displayName', value: 'ニックネーム（表示名）' },
    { key: 'segment', value: '会員ステータス' },
    { key: 'groups', value: '媒体' },
    { key: 'catchphrase', value: 'キャッチコピー' },
    { key: 'postalCode', value: '郵便番号' },
    { key: 'address', value: '住所' },
    { key: 'profile', value: 'プロフィール' },
    { key: 'remarks1', value: '備考1' },
    { key: 'remarks2', value: '備考2' },
    { key: 'email1', value: 'メールアドレス1' },
    { key: 'email2', value: 'メールアドレス2' },
    { key: 'phoneNumber1', value: '電話番号1' },
    { key: 'phoneNumber2', value: '電話番号2' },
    { key: 'gender', value: '性別' },
    { key: 'married', value: '結婚状況' },
    { key: 'hasChildren', value: '子の有無' },
    { key: 'birthDay', value: '生年月日' },
    { key: 'children', value: '子供' },
    { key: 'childbirthDay', value: '子供の生年月日' },
    { key: 'age', value: '年齢' },
    { key: 'uncertainAge', value: '生年月日の非公開' },
    { key: 'occupation', value: '職業' },
    { key: 'industry', value: '業種' },
    { key: 'job', value: '職種' },
    { key: 'height', value: '身長' },
    { key: 'weight', value: '体重' },
    { key: 'entriedAt', value: 'エントリー日' },
    { key: 'status', value: 'ステータス' },
    { key: 'anniversary', value: 'ハピコミュ登録日' },
    { key: 'otherSiteURL1', value: 'その他URL1' },
    { key: 'otherSiteURL2', value: 'その他URL2' },
    { key: 'otherSiteURL3', value: 'その他URL3' },
    { key: 'tags', value: 'タグ' },
    { key: 'confidentialTags', value: '編集部用タグ' },
    { key: 'remarks', value: '編集部備考' },
    { key: 'summary', value: 'PRボード' },
    { key: 'image1', value: 'プロフィール画像1(メイン)' },
    { key: 'image2', value: 'プロフィール画像2(サブ)' },
    { key: 'image3', value: 'プロフィール画像3(サブ)' },
    { key: 'image4', value: 'プロフィール画像4(サブ)' },
    { key: 'openPeriod', value: '公開日時' },
    { key: 'mainURL', value: 'BlogURL' },
    { key: 'twitter.screenName', value: 'Twitterアカウント' },
    { key: 'twitter.followersCount', value: 'Twitterフォロワー数' },
    { key: 'instagram.username', value: 'Instagramアカウント1' },
    { key: 'instagram2.username', value: 'Instagramアカウント2' },
    { key: 'instagram.followersCount', value: 'Instagramフォロワー数' },
    { key: 'youtube', value: 'Youtubeアカウント' },
    { key: 'tiktok', value: 'Tiktokアカウント' },
    { key: 'providerLINE', value: 'LINE認証状態' },
  ];

  private sortingheaders = [
    { key: 'id', value: 'ID' },
    { key: 'displayName', value: 'ニックネーム（表示名）' },
    { key: 'fullName', value: '氏名' },
    { key: 'order', value: 'sort' },
  ];

  constructor(
    private afStore: AngularFirestore,
    private apiService: MatchingService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.subscribeIndustries();
    this.subscribeJobs();
    this.subscribeOccupations();
    this.subscribeSegments();
    this.subscribeTags();
    this.subscribeConfidentialTag();
  }

  getHeaders() {
    return cloneDeep(this.headers);
  }

  getMemberSortingHeaders() {
    return cloneDeep(this.sortingheaders);
  }

  getSegmentName(data: any): string {
    const segment = data;
    return segment ? segment.name : '';
  }

  getGroupNames(groups: any[]): string {
    if (isNil(groups)) {
      return '';
    }
    const groupList = groups.map(group => {
      return group.groupName;
    });
    return isEmpty(groupList) ? '' : groupList.join('\n');
  }

  getPostalCode(address: any): string {
    const postalCodeDigitWithHyphen = 8;
    if (address === null) {
      return '';
    }
    const postalCode = address.postalCode ? address.postalCode : '';
    // ハイフン付きでfirestoreに登録されていればそのまま返却する
    if (postalCode.length === postalCodeDigitWithHyphen) {
      return postalCode;
    }
    if (postalCode === '') {
      return '';
    }
    // ハイフンが無い0から始まる郵便番号ははじめの0が削られた状態でCSV生成される為、
    // ハイフンなしでfirestoreに登録されていればハイフンを追加して返却する
    return postalCode.slice(0, 3) + '-' + postalCode.slice(3, 7);
  }

  getAddress(address: any): string {
    if (address === null) {
      return '';
    }

    return address.address ? address.address : '';
  }

  getChildren(children: Child[]): string {
    if (isNil(children)) {
      return '';
    }
    const childrenStrings = [];
    forEach(children, child => {
      childrenStrings.push(`${child.gender ? child.gender : ''}${','}`
        + `${this.convertFormatDate(child.birthDay)}`);
    });
    return isEmpty(childrenStrings) ? '' : childrenStrings.join('\n');
  }

  getBirthDay(birthDay: Date) {
    return this.convertFormatDate(birthDay);
  }

  getEntryDate(entry: any) {
    const date = new Date(entry);
    if (isNil(entry)) {
      return '';
    }
    if (isNaN(date.getDate())) {
      // tslint:disable-next-line: no-string-literal
      const timeStamp = entry['seconds'];
      return this.convertFormatDateTime(new Date(+timeStamp * 1000));
    }
    return this.convertFormatDateTime(entry);
  }

  getAge(birthDay: Date) {
    const timeDiff = Math.abs(Date.now() - new Date(birthDay).getTime());
    const year = birthDay ? Math.floor((timeDiff / (1000 * 3600 * 24)) / 365) : '';
    return year.toString();
  }

  getStatus(status: any): string {
    let stat = null;
    switch (status) {
      case 'entried':
        stat = 'エントリー中';
        break;
      case 'offered':
        stat = '内定';
        break;
      case 'approved':
        stat = '仮決定';
        break;
      case 'decided':
        stat = '本決定';
        break;
      case 'completed':
        stat = '完了';
        break;
      case 'canceled':
        stat = '見送り';
        break;
      default:
        stat = '';
        break;
    }
    return stat;
  }

  getOccupationName(list: any): string {
    const occupation = list;
    return occupation ? occupation.name : '';
  }

  getIndustryName(list: any): string {
    const industry = list;
    return industry ? industry.name : '';
  }

  getJobName(id: any): string {
    const job = id;
    return job ? job.name : '';
  }

  getAnniversary(anniversary: Date) {
    return this.convertFormatDate(anniversary);
  }

  getProviderLine(connections: any): boolean {
    if (isNil(connections)) {
      return false;
    }
    const line = connections;
    return line ? true : false;
  }

  getTagNames(tags: any[]): string {
    if (isNil(tags)) {
      return '';
    }
    const tagList = [];
    forEach(tags, id => {
      const tag = id;
      tagList.push(tag ? tag.name : '');
    });
    return isEmpty(tagList) ? '' : tagList.join('\n');
  }

  getConfidentialTagNames(confidentials: any): string {
    if (isNil(confidentials)) {
      return '';
    }
    const tagList = [];
    const id = confidentials.id;
    forEach(this.memberConfidentialTagList, tag => {
      if (_.includes(tag.member_confidentials, id)) {
        tagList.push(tag.name);
      }
    });
    return isEmpty(tagList) ? '' : tagList.join('\n');
  }

  getConfidentialRemarks(confidentials: MemberConfidential): string {
    if (isNil(confidentials)) {
      return '';
    }
    const data = confidentials;
    return data.remarks ? data.remarks : '';
  }

  getImages(images: any): string {
    return !images ? '' : images.url;
  }

  getOpenPeriod(openPeriod: any): string {
    if (isNil(openPeriod)) {
      return '';
    }
    return this.convertFormatDateTime(openPeriod);
  }

  getMainURL(mainURL: any): string {
    if (isNil(mainURL)) {
      return '';
    }
    return mainURL.URL ? mainURL.URL : '';
  }

  getTwitterScreenName(connections: any): string {
    if (isNil(connections)) {
      return '';
    }
    const twitter = connections;
    return twitter ? 'https://twitter.com/' + twitter.screenName : '';
  }

  getTwitterFollowersCount(connections: any): string {
    if (isNil(connections)) {
      return '';
    }
    const twitter = connections;
    return twitter ? twitter.followersCount : '';
  }

  getInstagramsUsername(connections: any[], index: number): string {
    if (isNil(connections)) {
      return '';
    }
    let instagram = null;
    connections.forEach(insta => {
      if (insta.order === index - 1) {
        instagram = insta;
      }
    });
    return instagram ? 'https://instagram.com/' + instagram.username : '';
  }

  getInstagramsFollowersCount(connections: any): string {
    if (isNil(connections)) {
      return '';
    }
    const instagram = connections;
    return instagram ? instagram.followersCount : '';
  }

  getTiktok(connections: any): string {
    if (isNil(connections)) {
      return '';
    }
    const tiktok = connections;
    return tiktok ? 'https://tiktok.com/@' + tiktok.uniqueId : '';
  }

  getYoutube(connections: any): string {
    if (isNil(connections)) {
      return '';
    }
    const youtube = connections;
    return youtube ? 'https://youtube.com/channel/' + youtube.idOfResponse : '';
  }

  private convertFormatDate(date: string | Date): string {
    return date ? formatDate(date, 'yyyy/MM/dd', this.locale) : '';
  }

  private convertFormatDateTime(dateTime: string | Date): string {
    return dateTime ? formatDate(dateTime, 'yyyy/MM/dd HH:mm', this.locale) : '';
  }

  subscribeTags() {
    this.apiService.getMasterGroup(
      'tag-masters',
      { parentMasterGroupId_null: true, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$)
    )
      .subscribe(list => this.memberTagList = list, err => console.error(err));
  }

  subscribeConfidentialTag() {
    this.apiService.getMasterGroup(
      'member-confidential-tag-masters',
      { parentMasterGroupId_null: false, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$)
    )
      .subscribe(list => {
        if (!isEmpty(list)) {
          let confidentialList: any;
          list = list.map(doc => {
            const tags = doc.member_confidentials;
            if (!isEmpty(tags)) {
              confidentialList = tags.map(docSnap => docSnap.id);
            }
            doc.member_confidentials = confidentialList;
            return doc;
          });
          this.memberConfidentialTagList = list;
        }
      }, err => console.error(err));
  }

  subscribeSegments() {
    this.apiService.getMasterGroup(
      'member-status-masters',
      { parentMasterGroupId_null: true, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$),
    )
      .subscribe(list => this.memberSegmentList = list, err => console.error(err));
  }

  subscribeOccupations() {
    this.apiService.getMasterGroup(
      'occupation-masters',
      { parentMasterGroupId_null: true, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(list => this.occupationList = list, err => console.error(err));
  }

  subscribeIndustries() {
    this.apiService.getMasterGroup(
      'industry-masters',
      { parentMasterGroupId_null: true, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(list => this.industryList = list, err => console.error(err));
  }

  subscribeJobs() {
    this.apiService.getMasterGroup(
      'job-masters',
      { parentMasterGroupId_null: true, _sort: 'order:ASC' }
    ).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(list => this.jobList = list, err => console.error(err));
  }

  setGroups(groupList: Array<Group>) {
    this.groups = groupList;
  }

  formatJSONForMemberCsv(json: any, headers: { key: string, value: string }[]): any {
    const formattedJson = [];
    forEach(json, (memberRow: any) => {
      const memberData = {};
      let insta01 = {};
      headers.forEach(head => {
        head.value === 'Instagramアカウント1' ?
          // tslint:disable-next-line: no-unused-expression
          insta01 = memberRow.connection_instagrams[0] : {};
      });

      forEach(headers, header => {
        switch (header.key) {
          case 'segment':
            memberData[header.value] = this.getSegmentName(memberRow.member_status_master);
            break;
          case 'groups':
            memberData[header.value] = this.getGroupNames(memberRow[header.key]);
            break;
          case 'postalCode':
            memberData[header.value] = this.getPostalCode(memberRow.address);
            break;
          case 'address':
            memberData[header.value] = this.getAddress(memberRow.address);
            break;
          case 'children':
            memberData[header.value] = this.getChildren(memberRow.children);
            break;
          case 'childbirthDay':
            memberData[header.value] = this.getChildren(memberRow.children);
            break;
          case 'birthDay':
            memberData[header.value] = this.getBirthDay(memberRow.birthDay);
            break;
          case 'age':
            memberData[header.value] = this.getAge(memberRow.birthDay);
            break;
          case 'entriedAt':
            memberData[header.value] = this.getEntryDate(memberRow.entriedAt);
            break;
          case 'status':
            memberData[header.value] = this.getStatus(memberRow.status);
            break;
          case 'occupation':
            memberData[header.value] = this.getOccupationName(memberRow.occupation_master);
            break;
          case 'industry':
            memberData[header.value] = this.getIndustryName(memberRow.industry_master);
            break;
          case 'job':
            memberData[header.value] = this.getJobName(memberRow.job_master);
            break;
          case 'height':
          case 'weight':
            memberData[header.value] = memberRow[header.key] ? Math.round(memberRow[header.key] * 100) / 100 : '';
            break;
          case 'anniversary':
            memberData[header.value] = this.getAnniversary(memberRow.registeredDate);
            break;
          case 'providerLINE':
            memberData[header.value] = this.getProviderLine(memberRow.lineId);
            break;
          case 'tags':
            memberData[header.value] = this.getTagNames(memberRow.tag_masters);
            break;
          case 'confidentialTags':
            memberData[header.value] = this.getConfidentialTagNames(memberRow.member_confidential);
            break;
          case 'remarks':
            memberData[header.value] = this.getConfidentialRemarks(memberRow.member_confidential);
            break;
          case 'image1':
            memberData[header.value] = this.getImages(memberRow.image1);
            break;
          case 'image2':
            memberData[header.value] = this.getImages(memberRow.image2);
            break;
          case 'image3':
            memberData[header.value] = this.getImages(memberRow.image3);
            break;
          case 'image4':
            memberData[header.value] = this.getImages(memberRow.image4);
            break;
          case 'openPeriod':
            memberData[header.value] = this.getOpenPeriod(memberRow.publishStartAt);
            break;
          case 'mainURL':
            const mainURL = { URL: memberRow.mainSiteURL, name: memberRow.mainSiteName } as any;
            memberData[header.value] = this.getMainURL(mainURL);
            break;
          case 'twitter.screenName':
            memberData[header.value] = this.getTwitterScreenName(memberRow.connection_twitter);
            break;
          case 'twitter.followersCount':
            memberData[header.value] = this.getTwitterFollowersCount(memberRow.connection_twitter);
            break;
          case 'instagram.username':
            memberData[header.value] = this.getInstagramsUsername(memberRow.connection_instagrams, 1);
            break;
          case 'instagram2.username':
            memberData[header.value] = this.getInstagramsUsername(memberRow.connection_instagrams, 2);
            break;
          case 'instagram.followersCount':
            memberData[header.value] = this.getInstagramsFollowersCount(insta01);
            break;
          case 'tiktok':
            memberData[header.value] = this.getTiktok(memberRow.connection_tik_tok);
            break;
          case 'youtube':
            memberData[header.value] = this.getYoutube(memberRow.connection_youtube);
            break;
          default:
            memberData[header.value] = memberRow[header.key];
            break;
        }
      });
      this.uid = memberRow.uid;
      formattedJson.push(memberData);
    });
    return formattedJson;
  }

  convertHeadersFromJPStringToKey(json: any[]) {
    const headersForUpdate = [
      { key: 'memberNumber', value: 'ID' },
      { key: 'foreignKey', value: '連携用アカウント' },
      { key: 'leaved', value: 'leaved' },
      { key: 'order', value: 'order' },
      { key: 'fullName', value: '氏名' },
      { key: 'fullNameKana', value: '氏名(ふりがな)' },
      { key: 'displayName', value: 'ニックネーム（表示名）' },
      { key: 'postalCode', value: '郵便番号' },
      { key: 'address', value: '住所' },
      { key: 'email1', value: 'メールアドレス1' },
      { key: 'email2', value: 'メールアドレス2' },
      { key: 'phoneNumber1', value: '電話番号1' },
      { key: 'phoneNumber2', value: '電話番号2' },
      { key: 'gender', value: '性別' },
      { key: 'married', value: '結婚状況' },
      { key: 'birthDay', value: '生年月日' },
      { key: 'uncertainAge', value: '生年月日の非公開' },
      { key: 'height', value: '身長' },
      { key: 'weight', value: '体重' },
      { key: 'occupation_master', value: '職業' },
      { key: 'industry_master', value: '業種' },
      { key: 'job_master', value: '職種' },
      { key: 'hasChildren', value: '子の有無' },
      { key: 'childbirthDay', value: '子供の生年月日' }, // not in csv items
      { key: 'registeredDate', value: 'ハピコミュ登録日' },
      { key: 'mainSiteName', value: 'BlogURLName' }, // not in csv item
      { key: 'mainSiteURL', value: 'BlogURL' },
      { key: 'connection_twitter', value: 'Twitterアカウント' },
      { key: 'connection_twitter_follower', value: 'Twitterフォロワー数' },
      { key: 'connection_instagrams_follower', value: 'Instagramフォロワー数' },
      { key: 'connection_instagram1', value: 'Instagramアカウント1' },
      { key: 'connection_instagram2', value: 'Instagramアカウント2' },
      { key: 'connection_tik_tok', value: 'Tiktokアカウント' },
      { key: 'connection_youtube', value: 'Youtubeアカウント' },
      { key: 'otherSiteURL1', value: 'その他URL1' },
      { key: 'otherSiteURL2', value: 'その他URL2' },
      { key: 'otherSiteURL3', value: 'その他URL3' },
      { key: 'member_status_master', value: '会員ステータス' },
      { key: 'groups', value: '媒体' },
      { key: 'tag_masters', value: 'タグ' },
      { key: 'member_confidential_tag_masters', value: '編集部用タグ' },
      { key: 'remarks', value: '編集部備考' },
      { key: 'publishStartAt', value: '公開日時' },
      { key: 'catchphrase', value: 'キャッチコピー' },
      { key: 'profile', value: 'プロフィール' },
      { key: 'remarks1', value: '備考1' },
      { key: 'remarks2', value: '備考2' },
      { key: 'summary', value: 'PRボード' },
      { key: 'image1', value: 'プロフィール画像1(メイン)' },
      { key: 'image2', value: 'プロフィール画像2(サブ)' },
      { key: 'image3', value: 'プロフィール画像3(サブ)' },
      { key: 'image4', value: 'プロフィール画像4(サブ)' },
    ];
    const convertedJson = [];
    forEach(json, memberRow => {
      const memberData = {};
      forEach(headersForUpdate, header => {
        if (header.value in memberRow) {
          memberData[header.key] = memberRow[header.value];
        }
      });
      // tslint:disable-next-line: no-string-literal
      memberData['uid'] = this.uid;
      convertedJson.push(memberData);
    });
    return convertedJson;
  }

  convertHeaders(json: any[]) {
    const headersForUpdate = [
      { key: 'memberNumber', value: 'ID' },
      { key: 'displayName', value: 'ニックネーム（表示名）' },
      { key: 'order', value: 'sort' },
      { key: 'fullName', value: '氏名' },
      
    ];
    const convertedJson = [];
    forEach(json, memberRow => {
      const memberData = {};
      forEach(headersForUpdate, header => {
        if (header.value in memberRow) {
          memberData[header.key] = memberRow[header.value];
        }
      });
      // tslint:disable-next-line: no-string-literal
      convertedJson.push(memberData);
    });
    return convertedJson;
  }
}
