import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import * as moment from 'moment';
import { get, map as _map } from 'lodash-es';

import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { Path } from '@lu/path';
import {
  Group,
  Payment,
  Member,
  CategoryMaster,
  ProjectKindMaster,
  Project,
  Reward,
  Entry,
  Report
} from '@lu/models';

import { MatchingService } from '@lu/services/matching.service';
import { MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';

export interface QueryObjPayment {
  created_at_gte: string;
  created_at_lte: string;
  group: Array<string>;
  keyword: string;
}

type PaymentAggregation =
  { projectName: string } &
  { projectId: string } &
  { memberNumber: number } &
  { total: number } &
  { uid: number } &
  Pick<Payment, 'currency'> &
  Pick<Member, 'fullName' | 'fullNameKana' | 'displayName'>;

@Component({
  selector: 'lu-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit, OnDestroy {
  public searchForm = new FormGroup({
    month: new FormControl(null),
    group: new FormControl(''),
    keyword: new FormControl(''),
  });
  public monthList: string[] = [moment(new Date()).startOf('M').toISOString(true)];
  public groupList: Group[];
  public role: string;
  public categoryList: Array<CategoryMaster>;
  public segmentList: Array<ProjectKindMaster>;
  public orderList: Array<Project>;
  public orders: Array<Project>;

  public rewardList: Array<Reward> = [];
  public entryList: Array<Entry> = [];
  public reportList: Array<Report> = [];
  public paymentAggs: Array<PaymentAggregation>; // PaymentAggregation[] ;
  public payments: Array<Payment>;

  public orderColumnToDisplay = ['orderNumber', 'orderName', 'segment', 'eventPeriod'];
  public columnToDisplay = ['memberNumber', 'fullName', 'total', 'empty'];
  private onDestroy$ = new Subject();
  public readonly path = Path;
  public paymentList = [];
  public monthListData = [];
  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private csvService: XLXSCSVService,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    const { month } = this.aRoute.snapshot.queryParams;
    const term = new Date(month);
    this.searchForm.patchValue({
      month: isNaN(term.valueOf()) ?
        this.monthList[0] :
        moment(term).startOf('M').toISOString(true)
    });
    this.aRoute.data.subscribe(data => {
      this.groupList = data.groups;
      this.role = data.servicePermissions[0].role;
    });

    this.generateTerm();
    this.subscribeCategories();
    this.subscribeSegments();
    this.getReward();
    this.getCompletedReports();
    this.getCompletedEntries();
    this.search();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  subscribeCategories() {
    this.apiService.getMaster('category-masters', { parentMasterGroupId_null: false })
      .subscribe(
        categoryItem => {
          if (categoryItem) {
            this.categoryList = categoryItem;
          }
        }, error => {
          console.error('Something was wrong in category list ====> ', error);
        }
      );
  }

  subscribeSegments() {
    this.apiService.getMaster('project-kind-masters', { parentMasterGroupId_null: false })
      .subscribe(
        pjKindItem => {
          if (pjKindItem) {
            this.segmentList = pjKindItem;
          }
        }, error => {
          console.error('Something was wrong in project-kind-masters(item type) list ====> ', error);
        }
      );
  }

  lastday(year, month) {
    return new Date(year, month, 0).getDate();
  }

  getSearchQuery() {
    const formValue = this.searchForm.value;
    const queryObj = {} as QueryObjPayment;
    if (formValue.month || formValue.group || formValue.keyword) {
      if (formValue.month) {
        const date = new Date((formValue.month));
        const year = date.getFullYear();
        // tslint:disable-next-line: no-shadowed-variable
        const month = date.getMonth() + 1;
        const months = month < 10 ? '0' + month : month;
        const lastday = this.lastday(year, month);
        queryObj.created_at_gte = year + '-' + months + '-01T00:00:00.000Z';
        queryObj.created_at_lte = year + '-' + months + '-' + lastday + 'T23:59:59.000Z';
      }
      if (formValue.group) {
        queryObj.group = formValue.group;
      }
      if (formValue.keyword) {
        queryObj.keyword = formValue.keyword;
      }
    } else {
      if (this.role === 'groupEditor' || this.role === 'groupAdmin') {
        queryObj.group = this.groupList.map(group => group.id);
      }
      return queryObj;
    }
    return queryObj;
  }


  getUniqueData(data) {
    const unique = [];
    data.map(x => unique.filter(a => a.id === x.id).length > 0 ? null : unique.push(x));
    return unique;
  }

  // downloadPaymentListCsvで使用しているデータ
  getReward() {
    this.apiService.getReward({ _limit: -1 }).subscribe((rewardData) => {
      this.rewardList = rewardData;
      this.rewardList = this.getUniqueData(this.rewardList);
    }, error => {
      console.error(error);
    });
  }

  // downloadPaymentListCsvで使用しているデータ
  getCompletedEntries() {
    this.apiService.getEntries({ _limit: -1 }).subscribe((entries) => {
      this.entryList = entries;
      this.entryList = this.getUniqueData(this.entryList);
    }, error => {
      console.error(error);
    });
  }

  getCompletedReports() {
    this.apiService.getReport({ _limit: -1 }).subscribe((report) => {
      this.reportList = report;
      this.reportList = this.getUniqueData(this.reportList);
    }, error => {
      console.error(error);
    });
  }

  async search() {
    this.paymentAggs = void 0;
    this.orderList = void 0;
    const query = this.getSearchQuery() as QueryObjPayment;
    const termString = moment(this.searchForm.value.month).format('YYYY-MM');
    this.router.navigate([], {
      queryParams: { month: termString },
      replaceUrl: true,
    });

    this.paymentAggs = [];

    const paramPayment = {
      'project.eventEndAt_gte': query.created_at_gte,
      'project.eventEndAt_lte': query.created_at_lte,
      'project.projectName_contains': query.keyword === undefined ? null : query.keyword,
      'project.administrativeGroups': query.group === undefined ? null : query.group,
      _limit: -1
    };
    if (this.role === 'groupEditor' || this.role === 'groupAdmin') {
      paramPayment['member.groups'] = this.groupList.map(group => group.id);
    }

    const getPaymentList = () => {
      return new Promise<Payment[]>(payresolve => {
        this.apiService.getPayment(paramPayment)
          .subscribe(pay => {
            payresolve(pay);
          });
      });
    };
    const paymentList = await getPaymentList();
    this.paymentList = paymentList;
    const pjlist = paymentList.map((data: any) => {
      if (!data.project) {
        return ;
      }
      return data.project;
    });
    const filteredPjId = pjlist.map(data => {
      if (data) {
        return data.id;
      }
    });

    const memberlist = [];
    this.orderList = [];

    paymentList.map((payment: any) => {
      if (payment.member) {
        memberlist.push(payment.member.id);
      }
    });

    const filteredMember = paymentList.filter((element: any) => element.member ? memberlist.includes(element.member.id) : null);
    const filteredPayment = filteredMember.filter((element: any) => element.project ? filteredPjId.includes(element.project.id) : null);

    filteredPayment.map((payment: any) => {
      if (payment) {
        this.paymentAggs.push({
          currency: payment.currency,
          displayName: payment.member ? payment.member.displayName : null,
          fullName: payment.member ? payment.member.fullName : null,
          fullNameKana: payment.member ? payment.member.fullNameKana : null,
          memberNumber: payment.member ? payment.member.id : null,
          uid: payment.member ? payment.member.id : null,
          total: payment.amount,
          projectName: payment.project ? payment.project.projectName : null,
          projectId: payment.project ? payment.project.id : null,
        });
      }
    });

    pjlist.map(pj => {
      this.paymentAggs.map(paymentAgg => {
        if (pj && paymentAgg && pj.id === paymentAgg.projectId) {
          this.orderList.push(pj);
        }
      });
    });
    this.orderList = this.getUniqueData(this.orderList)
      .sort((a, b) => a.id - b.id);

    // メンバーごとの報酬金額の集計
    const output = this.paymentAggs.reduce((final, doc) => {
      const isAlready = final.find((value) => value.uid === doc.uid);
      if (!isAlready) {
        final.push(doc);
      } else {
        const indexFinal = final.indexOf(isAlready);
        final[indexFinal].total = parseFloat(final[indexFinal].total) + parseFloat(String(doc.total));
      }
      return final;
    }, []);

    this.paymentAggs = output
      .sort((a, b) => a.memberNumber - b.memberNumber);
  }

  generateTerm() {
    const paramPayment = { _limit: -1 };
    if (this.role === 'groupEditor' || this.role === 'groupAdmin') {
      paramPayment['member.groups'] = this.groupList.map(group => group.id);
    }
    this.apiService.getPayment(paramPayment).subscribe(termList => {
      // paidAt => created_atへの変更は問題ないか
      const moments = termList.map(t => moment(t.created_at).startOf('M'));
      const current = moment(this.searchForm.value.month);

      // 今月の値をいれる
      moments.push(current);
      moments.sort((a, b) => a.valueOf() - b.valueOf());
      const dateStrings = moments.map(m => m.toISOString(true));
      this.monthListData = [...new Set(dateStrings)];
      const months = [];
      // 重複している月を消す
      this.monthListData.forEach(month => {
        if (month) {
          months.push(month);
        }
      });
      this.monthList = months;
    },
      error => {
        console.error('error', error);
      });
  }

  getColumnHeader(column: string) {
    switch (column) {
      case 'memberNumber': return 'ID';
      case 'fullName': return '氏名';
      case 'total': return '総支払額';
      default:
    }
  }

  generateCellWidth(column: string, payment: any) {
    switch (column) {
      case 'memberNumber':
        return get(payment, column).length;
      case 'fullName':
        return get(payment, column).length * 2;
      case 'total':
        return String(payment.total.amount).length + 2;
      default:
        return null;
    }
  }

  async downloadPaymentListCsv() {
    const month = this.aRoute.snapshot.queryParams.month || this.searchForm.value.month;
    const fileName = `月次案件実績_${moment(month).format('YYYY-MM')}.csv`;
    const csvDataList = [];

    const output = this.paymentList.reduce((final, doc) => {
      const isAlready = final.find((value) => {
      if (value.member && doc.member && value.project && doc.project) {
        return value.member.id === doc.member.id && value.project.id === doc.project.id;
      }});
      if (!isAlready) {
        final.push(doc);
      } else {
        const indexFinal = final.indexOf(isAlready);
        final[indexFinal].amount = parseFloat(final[indexFinal].amount) + parseFloat(String(doc.amount));
      }
      return final;
    }, []);

    this.paymentList = output
    .sort((a, b) => {
      if (a.project && b.project) {
        return a.project.id - b.project.id;
      }}
     );

    this.paymentList.forEach((orderList: any) => {
      let projectKindName = null;
      let category = [];
      let categoryNames = [];

      const entryPj = this.entryList.filter((entries: any) =>
      (entries.project && orderList.project) ? entries.project.id === orderList.project.id : null);
      const entry = entryPj.filter((data: any) => {
        // tslint:disable-next-line: no-string-literal
        if (data && data.member && orderList.member) {
          return data.member.id === orderList.member.id;
        }
      });
      if (orderList.project && orderList.project.project_kind_master) {
        projectKindName = this.segmentList.filter((data: any) => {
          // tslint:disable-next-line: no-string-literal
          return data ? data.id === orderList.project.project_kind_master : null;
        });
      }
      category = this.categoryList.filter((data) => {
        // tslint:disable-next-line: no-string-literal
        const categories = data.projects ? data.projects.filter((pj: any) =>  pj.id === orderList.project.id) : [];
        return  categories.length > 0;
      });
      if (category.length > 0) {
        categoryNames = category.map(catName => catName.name);
      }
      csvDataList.push({
        ID: orderList.project.id,
        案件名: orderList.project.projectName,
        案件種別: projectKindName ? projectKindName.length > 0 ? projectKindName[0].name : '-' : '-',
        メンバー名: orderList.member && orderList.member.fullName ? orderList.member.fullName : '-',
        ニックネーム: orderList.member && orderList.member.displayName ? orderList.member.displayName : '-',
        レポート完了日: (entry.length > 0 && entry[0].approvedAt) ? moment(entry[0].approvedAt).format('YYYY/MM/DD').toString() : '-',
        案件カテゴリ: category.length > 0 ? categoryNames.join('\n') : '-',
        金額: orderList.amount ? String(orderList.amount) : '0'
      });
    });
    this.csvService.downloadCSV(csvDataList, fileName);
    console.log(csvDataList, 'csvDataList');
  }
}
