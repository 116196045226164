import { Component, OnInit, Input } from '@angular/core';
import { forEach, map as _map, isArray, size, flatten } from 'lodash-es';

import { ValidationRules } from '@lu/definitions/validators';
import {
  Member,
} from '@lu/models';
import { environment } from '@lu/environment';
import * as _ from 'lodash';

export type Profile = any;
export type ReadProfile = Member;

const validationRules = new ValidationRules();
const memberRules = validationRules.member();
@Component({
  selector: 'lu-user-sort-batch-update',
  templateUrl: './user-sort-batch-update.component.html',
  styleUrls: ['./user-sort-batch-update.component.scss']
})
export class UserSortBatchUpdateComponent implements OnInit {
  @Input() profiles: ReadProfile;
  public displayCols = [
    { key: 'memberNumber', value: 'ID' },
    { key: 'displayName', value: 'ニックネーム（表示名）' },
    { key: 'fullName', value: '氏名' },
    { key: 'order', value: 'sort' },
  ];

  constructor(
  ) { }

  ngOnInit() {
    if (!environment.production) {
      console.log('profiles:', this.profiles);
    }
  }

  async validateMembersJSON(json: any[]) {
    if (!json || json.length === 0) {
      throw [];
    }
    const promises: Promise<string[]>[] = [];
    let index = 1;
    forEach(json, elem => promises.push(new Promise(resolve => {
      const messages: string[] = [];
      if (!elem.memberNumber) {
        messages.push(`${index}行目: 「ID」は必須項目です。`);
      }
      if (elem.fullName === undefined) {
        messages.push(`${index}行目: 「氏名」は必須項目です。`);
      }
      if (elem.order === undefined) {
        messages.push(`${index}行目: 「sort」は必須項目です。`);
      }
      if (elem.fullName !== undefined && size(elem.fullName) <= 0) {
        messages.push(`${index}行目: 「氏名」は必須項目です。`);
      }
      if (elem.order !== undefined && size(elem.order) <= 0) {
        messages.push(`${index}行目: 「sort」は必須項目です。`);
      }
      if (elem.order
        && (isNaN(elem.order))) {
        messages.push(`${index}行目: 「sort」は数値で指定してください。(${elem.order})`);
      }
      resolve(messages);
      index++;
    })));
    const results = flatten(await Promise.all(promises));
    if (results.length) {
      throw { results };
    }
  }

  parseJson(list: any[]): ReadProfile[] {
    const profiles: ReadProfile[] = [];
    forEach(list, item => {
      const profile: Partial<ReadProfile> = {};
      forEach(item, (val, key) => {
        let prop: any = val || null; // if empty string, convert to null.
        // Parse values
        switch (key) {
          case 'memberNumber':
            prop = prop ? Number.parseInt(prop, 10) : prop;
            break;
        }
        profile[key] = prop;
      });
      // Force values.
      if (!profile.hasChildren) {
        profile.children = [];
      }
      profiles.push(profile as any);
    });
    return profiles;
  }

  clean(member: any) {
    for (const propName in member) {
      if ((member[propName] === null || member[propName] === undefined) ||
        (isArray(member[propName]) && (member[propName]).length <= 0)) {
        delete member[propName];
      }
    }
    return member;
  }

  covertToProfile(profile: any, mode: any) {
    let member: any = {
      leaved: false
    };
    this.setMemberItem('sort', member, profile.order ? profile.order : 0);
    this.setMemberItem('id', member, profile.memberNumber);
    this.setMemberItem('foreignKey', member, profile.foreignKey);
    this.setMemberItem('fullName', member, profile.fullName);
    this.setMemberItem('displayName', member, profile.displayName);

    return member;
  }

  setMemberItem(keyName, member, value, customValue = null) {
    if (value !== undefined) {
      if (customValue) {
        member[keyName] = customValue;
      } else {
        member[keyName] = value;
      }
    }
  }
}
