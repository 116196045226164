import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { PasswordConfirmService } from '@lu/services/password-confirm.service';
import { Path } from '@lu/path';

@Injectable()
export class PasswordConfirmResolver implements Resolve<any> {
   state = false;
   passwordData: any;
   path = Path;

   constructor(
      private matchservice: PasswordConfirmService,
      private router: Router,
   ) {
      const getdata = this.matchservice.checkAuntenticate().then(data => {
         if (!data) {
            this.router.navigate([this.path.user.list]);
         }
         return data;
      });
      if (getdata) {
         this.passwordData = getdata;
         this.state = true;
      } else {
         this.state = false;
      }
   }

   resolve(
   ) {
      return this.state ? this.passwordData : null;
   }
}
