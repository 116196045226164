import { Component, ComponentFactoryResolver, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormArray
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import * as _ from 'lodash';
import { take, map, switchMap, filter } from 'rxjs/operators';
import { MatchingService } from '@lu/services/matching.service';
import { PasswordConfirmService } from '@lu/services/password-confirm.service';
import { defer, from, of } from 'rxjs';
import { DialogService } from '@lu/services/dialog.service';
import { MemberService } from '@lu/services/member.service';
import {
  Group,
  Member,
  NewMember
} from '@lu/models';
import { Path } from '@lu/path';
import * as moment from 'moment';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { ConfirmationDialogData } from '@lu/components/confirmation-dialog/confirmation-dialog.component';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { ReadProfile } from '@lu/components/user-batch-registration/user-batch-registration.component';
import { chunk } from 'lodash';
import { UserSortBatchUpdateComponent } from '@lu/components/user-sort-batch-update/user-sort-batch-update.component';
@Component({
  selector: 'lu-user-sort',
  templateUrl: './user-sort.component.html',
  styleUrls: ['./user-sort.component.scss']
})
export class UserSortComponent implements OnInit {
  @ViewChild('updateFileInput', { static: false }) private updateFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('userBatchContainer', { static: false, read: ViewContainerRef }) private userBatchContainer: ViewContainerRef;
  // @ViewChild('userBatchContainer', { static: false, read: ViewContainerRef }) userBatchContainer: ViewContainerRef 

  @ViewChild('loadedUserList', { static: false }) private loadedUserList: TemplateRef<HTMLElement>;
  public groupSearchForm = new FormGroup({
    group: new FormControl(null),
    loading: new FormControl(false)
  });
  public groupMemberItemsForm = new FormArray([]);
  public groups: Array<Group>;
  public members: Array<Member>;
  public path = Path;
  public queryParams: Params; // for debug
  public csvHeadersValue: any;
  public groupId: any;

  constructor(
    private aRoute: ActivatedRoute,
    private apiService: MatchingService,
    private passwordConfirmService: PasswordConfirmService,
    private dialog: DialogService,
    private memberService: MemberService,
    private CSVService: XLXSCSVService,
    private componentResolver: ComponentFactoryResolver,
    private router: Router
  ) { }

  ngOnInit() {
    this.queryParams = this.aRoute.snapshot.queryParams;
    this.aRoute.data.subscribe(data => {
      if (data.groups) {
        const defaultGroup = _.get(_.head(data.groups), 'id');
        this.groupId = defaultGroup;
        this.groups = data.groups;
        this.groupSearchForm.patchValue({ group: defaultGroup });
        this.getGroupMembers(defaultGroup);
        this.searchWhenGroupChange();
      }
    });
  }

  searchWhenGroupChange() {
    this.groupSearchForm.get('group')
      .valueChanges
      .subscribe(groupId => {
        this.getGroupMembers(groupId);
        this.groupId = groupId;
      });
  }

  getGroupMembers(groupId: string) {
    this.groupSearchForm.patchValue({ loading: true });
    this.groupMemberItemsForm.clear(); // Refresh controller
    const param = {
      groups: groupId,
      _limit: -1,
      _sort: 'order:ASC'
    };
    this.apiService.getMember(param)
      .subscribe(members => {
        this.members = members;
        this.members.forEach(m => console.log(m.id, m.order, m.displayName));
        this.setControls(members);
        this.groupSearchForm.patchValue({ loading: false });
      }, err => {
        console.error(err);
        this.groupSearchForm.patchValue({ loading: false });
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.applyOrderChange();
  }

  sortPredicate(a: Pick<Member, 'order'>, b: Pick<Member, 'order'>): number {
    const orderDiff = a.order - b.order;
    if (orderDiff === 0 || isNaN(orderDiff)) {
      const [aAt, bAt] = [_.get(a, 'updated_at'), _.get(b, 'updated_at')];
      console.log(aAt, bAt);
      // 後に更新された方を優先とする
      return aAt && bAt ?
        (new Date(bAt)).valueOf() - (new Date(aAt)).valueOf() : -1;
    }
    return orderDiff;
  }

  /**
   * apply order changed.
   */
  async applyOrderChange() {
    const savedCtrls = this.groupMemberItemsForm.controls;
    const updatedAt = new Date().toISOString();
    const promises: Promise<void>[] = [];
    const min = Math.min(...savedCtrls.map(c => c.value.order));
    promises.push(
      ...savedCtrls.map(async (ctrl, pos) => {
        const newOrder = pos + min;
        const { id, order } = ctrl.value;
        if (newOrder === order) {
          // No changes.
          return;
        }
        ctrl.patchValue({ pending: true });
        try {
          // 詳細画面での更新に被る可能性があるのでorderのみ更新する
          const userId: string = id;
          const update = { order: newOrder, updated_at: updatedAt };
          this.apiService.updateMemberOrder(userId, { order: newOrder }).subscribe(updatedMember => {
            if (updatedMember) {
              return updatedMember;
            }
          }, error => console.error(error));

          ctrl.patchValue(update);
        } catch (err) {
          // 更新失敗した場合ログを出力してスキップ
          console.error(err.message, { order, newOrder, id, data: ctrl.value });
        } finally {
          ctrl.patchValue({ pending: false });
        }
      })
    );

    await Promise.all(promises);

    this.groupMemberItemsForm.controls.sort((aCtrl, bCtrl) => {
      const [a, b]: Pick<Member, 'order'>[] = [aCtrl.value, bCtrl.value];
      return this.sortPredicate(a, b);
    });
  }

  // 必要なもののみ
  defaultCtrl() {
    return new FormGroup({
      uid: new FormControl(null),
      order: new FormControl(0),
      id: new FormControl(0),
      fullName: new FormControl(null),
      fullNameKana: new FormControl(null),
      displayName: new FormControl(null),
      image1: new FormControl(null),
      // metafield
      pending: new FormControl(false),
      data: new FormControl(null),
    } as { [K in keyof Partial<NewMember>]: AbstractControl });
  }

  setControls(memebrs: Member[]) {
    this.groupMemberItemsForm.reset([]);
    memebrs.forEach(member => {
      try {
        const ctrl = this.defaultCtrl();
        ctrl.patchValue({ ...member, data: member });
        this.groupMemberItemsForm.push(ctrl);
      } catch (err) {
        console.error(err, { data: member });
      }
    });
  }

  async downloadMembersCsv() {
    let query = {} as any; // search: [], size: 1000, from: 0
    const memberResult: Member[] = [];
    const [applyText, cancel] = ['OK', false];
    const passwordConfirm: any = await this.passwordConfirmService.passwordConfirm();
    if (passwordConfirm.length > 0) {
      const param = {
        groups: this.groupId ? this.groupId : 1,
        _limit: -1,
        _sort: 'order:ASC'
      };

      const request$ = body => this.apiService.getMember(param)
        .pipe(
          switchMap((result: Member[]) => {
            // Has no permissions
            const promises: Promise<Member>[] = [];
            result.forEach(member => promises.push(
              new Promise(async resolve => {
                resolve({ ...member });
              })
            ));
            return from(Promise.all(promises));
          }),
          switchMap(results => {
            memberResult.push(...results);
            return of(memberResult);
          }),
        );
      const dialog = this.dialog.openLoadingDialog({
        data: { text: 'CSVを作成しています...' },
        disableClose: true,
      });
      const headers = this.memberService.getMemberSortingHeaders();
      let csvHeaders = [];
      let csvHeadersValue = [];
      csvHeaders = headers.filter((data) => {
        return data.key;
      });
      csvHeadersValue = [...csvHeadersValue, ...csvHeaders];
      this.csvHeadersValue = csvHeadersValue;

      request$(query).subscribe((member: any) => {
        // JSON、ヘッダーを渡してメンバー一覧のCSVを作れる形式のJSONに整形する
        const formattedJson = this.memberService.formatJSONForMemberCsv(member, this.csvHeadersValue);
        const fileName = `member-sort-list_${moment().format('YYYY-MM-DD')}.csv`;
        this.CSVService.downloadCSV(formattedJson, fileName);
        dialog.close();
      }, (err: any) => {
        console.error(err);
        dialog.afterClosed().subscribe(() => {
          const data: ConfirmationDialogData = {
            text: 'CSVが作成出来ませんでした。',
            apply: false,
            cancelText: '確認'
          };
          this.dialog.openConfirmDialog({ data });
        });
        dialog.close();
      });
    } else {
      this.dialog.openConfirmDialog({
        data: {
          applyText,
          cancel,
          text: 'パスワードが一致しません。',
        }
      });
    }
  }

  browseFile(): void {
    const fileInput = this.updateFileInput;
    fileInput.nativeElement.value = null;
    fileInput.nativeElement.click();
  }

  openConfirmationDialog(profiles: ReadProfile[], mode: string) {
    const dialogRef = this.dialog.openTemplateDialog(this.loadedUserList, {
      data: { profiles, mode },
      autoFocus: false
    });
    return dialogRef;
  }

  openProcessFailDialog(err: any) {
    let html = err && err.length > 0 ? '<div>選択している媒体以外のメンバーのIDが含まれています。</div>' : '';
    if (Array.isArray(err) && err.length > 0) {
      _.forEach(err as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        html += `<div>${message}</div>`;
      });
    }  else {
      html += `<div>更新処理が失敗しました。</div>`;
    }
    this.dialog.openConfirmDialog({
      autoFocus: false,
      data: {
        html,
        cancel: false
      }
    });
  }

  showErrorForUpdate(err: Error) {
    this.openProcessFailDialog(err);
  }

  openInvalidErrorDialog(err: any) {
    let html = '<div>CSVファイルの以下の入力内容に誤りがあります。</div>';
    if (Array.isArray(err.results)) {
      _.forEach(err.results as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        html += `<div>${message}</div>`;
      });
    } else {
      html += `<br /><div>CSVの形式が正しくありません。</div>`;
    }
    this.dialog.openConfirmDialog({
      panelClass: ['mat-dialog-scrollable'],
      minWidth: '600px',
      maxHeight: '90vh',

      autoFocus: false,
      data: {
        html,
        apply: false
      }
    });
  }

  generateMembersJSON(mode: string): Promise<void> {
    const data: LodingDialogData = { text: 'CSVデータ読み込み中...' };
    const loadingDialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const fileInput = this.updateFileInput;
    const file = _.nth(fileInput.nativeElement.files, 0);
    const factory = this.componentResolver.resolveComponentFactory(UserSortBatchUpdateComponent);
    const component = this.userBatchContainer.createComponent(factory);

    if (!(file instanceof File)) {
      return;
    }
    this.CSVService.convertFileToJSON(file)
      .pipe(
        map(list => {
          if (list) {
            const convertedList = this.memberService.convertHeaders(list);
            return component.instance.parseJson(convertedList);
          }
        }),
        // Validate read values.
        switchMap(profiles => defer(async () => {
          await component.instance.validateMembersJSON(profiles);
          loadingDialog.close();
          return profiles;
        })),
        switchMap(profiles => this.openConfirmationDialog(profiles, mode).afterClosed()
          .pipe(
            filter(result => !!result),
            map(() => profiles.map(prof => component.instance.covertToProfile(prof, mode)))
          )
        )
      )
      .subscribe(async members => {
        const [apply, cancelText] = [false, '確認'];
        // tslint:disable-next-line: no-shadowed-variable
        const data: LodingDialogData = { text: `メンバーを${mode === 'create' ? '登録' : '更新'}しています...` };
        const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });

        const chunkSize = 100;
        const memberChunks = chunk(members, chunkSize);
        let errshow = false;
        let errMsg = null;
        const errResults = [];
        const errLine = [];
        const memberUpdate = () => {
          return new Promise((myresolve, myreject) => {
            const results = [];
            memberChunks.forEach((member: any, i: number) => {
              const currentSize = i * chunkSize + member.length;
              data.text = `メンバーを更新しています... (${currentSize}/${members.length})`;
              member.forEach(async (m: any, index) => {
                const checkMember = this.members.map(e => e.id)
                  .includes(m.id);
                if (checkMember === false) {
                  errLine.push(`${(i * chunkSize) + index + 2}行目`);
                }
                errResults.push(checkMember);
              });
              if (errResults.includes(false)) {
                errshow = true;
                errMsg = errLine;
              } else {
                errshow = false;
              }

              if (!errshow) {
                member.forEach(async (m: any, index) => {
                  this.apiService.updateMemberOrder(m.id, { order: m.sort })
                    .subscribe(async result => {
                      results.push(result);
                      if (results.length === members.length) {
                        myresolve(results);
                      }
                    }, err => {
                      dialog.close();
                      console.error(err);
                      errshow = true;
                      errMsg = err;
                      myreject(err);
                    });
                });
              } else {
                errshow = true;
                myreject(errMsg);
              }
            });
          });
        };

        try {
          await memberUpdate();
        } catch (err) {
          dialog.close();
          errshow = true;
        }
        if (errshow) {
          dialog.close();
          this.showErrorForUpdate(errMsg);
        } else {
          dialog.close();
          this.dialog.openConfirmDialog({
            data: {
              apply,
              cancelText,
              text: 'メンバー更新が完了しました。',
            }
          }).afterClosed().subscribe(() => this.getGroupMembers(this.groupId));
        }

      }, err => {
        console.error(err);
        loadingDialog.close();
        this.openInvalidErrorDialog(err);
      });
  }
}
