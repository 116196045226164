import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';

import { findIndex, isEqual } from 'lodash-es';

import { Member } from '@lu/models';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { EsMemberVirutualScrollStrategy } from '@lu/pages/user-list/es-member-virutual-scroll-strategy';
import { UserSearchComponent } from '@lu/components/user-search/user-search.component';
import { DialogService } from '@lu/services/dialog.service';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '@lu/environment';

@Component({
  selector: 'lu-member-invite-dialog',
  templateUrl: './member-invite-dialog.component.html',
  styleUrls: ['./member-invite-dialog.component.scss'],
  providers: [
    EsMemberVirutualScrollStrategy,
  ],
})
export class MemberInviteDialogComponent {
  @ViewChild(UserSearchComponent, { static: false }) public invitationMemberSearch: UserSearchComponent;
  public userSearchForm = new FormGroup({});
  public pageSize = -1;
  public invitationMembers: Array<Member> = [];

  constructor(
    public dialogRef: MatDialogRef<MemberInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataSource: EsMemberVirutualScrollStrategy,
    private dialog: DialogService,
    private http: HttpClient,
  ) { }

  unionArray(list: any[], item) {
    if (findIndex(list, o => isEqual(o, item)) >= 0) {
      return;
    }
    list.push(item);
  }

  arrayRemove(list: any[], item) {
    const index = findIndex(list, o => isEqual(o, item));
    if (index === -1) {
      return;
    }
    list.splice(index, 1);
  }

  searchInvitationMember() {
    const search = this.invitationMemberSearch.buildSearchQurey();
    search._limit = this.pageSize;
    search._start = 0;
    this.dataSource.searchMembers({
      search,
    });
  }

  sendInviteMail(list: Array<Member>) {
    const data: LodingDialogData = {
      text: '招待メールを送信しています...',
    };
    let dialog: MatDialogRef<any>;
    this.dialog.closeAll()
      .pipe(
        take(1),
        switchMap(() => {
          const members = list.map(o => o.id);
          dialog = this.dialog.openLoadingDialog({
            autoFocus: false,
            disableClose: true,
            data,
          });
          return this.http.post(`${environment.apiEndpoint}/auth/action/request/invitation`, { members });
        })
      )
      .subscribe(async () => {
        data.hiddenBar = true;
        data.text = '招待メールを送信しました。';
        await new Promise<void>(r => setTimeout(() => r(), 1500));
        dialog.close();
      }, async err => {
        console.error(err);
        data.color = 'warn';
        data.value = 100;
        data.mode = 'determinate';
        data.text = '招待メールを送信できませんでした。';
        await new Promise<void>(r => setTimeout(() => r(), 2000));
        dialog.close();
      });
  }

}
