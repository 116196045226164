import { Injectable } from '@angular/core';
import { MatchingService } from '@lu/services/matching.service';
import * as _ from 'lodash';
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import { DialogService } from '@lu/services/dialog.service';
import {  AdminUser } from '@lu/models';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class PasswordConfirmService {
  constructor(
    private apiService: MatchingService,
    private dialog: DialogService,
    private afAuth: AngularFireAuth
  ) {
   }

  async checkAuntenticate(): Promise<any> {
    const [applyText, cancel] = ['OK', false];
    const passwordEnterDate = sessionStorage.getItem('PasswordConfirmDate');
    if (!passwordEnterDate) {
      const passwordConfirm: any = await this.passwordConfirm();
      if (passwordConfirm && passwordConfirm.length > 0) {
        sessionStorage.setItem('PasswordConfirmDate', Date.now().toString());
        return passwordConfirm;
      } else {
        this.dialog.openConfirmDialog({
          data: {
            applyText,
            cancel,
            text: 'パスワードが一致しません。',
          }
        });
        return ;
      }
    } else {
      return [];
    }
  }

  async passwordConfirm() {
    const [applytext, cancel ] = ['認証', false];
    const dialog = this.dialog.openPasswordConfirmDialog({
      data: {
        applytext,
        cancel,
        text: 'パスワードを入力してください',
      }
    });
    return new Promise((resolve, reject) => {
      dialog.afterClosed().subscribe(async pass => {
        const groupPasswordList = await this.callGroupPass();
        let filteredpass: any;
        const authUid = this.afAuth.auth.currentUser.uid;
        this.apiService.getAdminUser({ uid: authUid })
          .subscribe((permission: any) => {
              if (permission && permission.length > 0) {
              if (permission[0].role === 'serviceAdmin') {
                  const filteredList = groupPasswordList.filter(data => {
                      return data.groupId === null && data.role === AdminUser.RoleEnum.ServiceAdmin;
                  });
                  filteredpass = filteredList.filter(passwordData => {
                      return passwordData.password === ((sha256('Group' + pass + md5(String(passwordData.id)))).toString());
                  });
              } else if (permission[0].role === 'groupAdmin' || permission[0].role === 'groupEditor') {
                  const pjIds = permission[0].groups.map(ma => ma ? ma.id : null);
                  const filteredList = groupPasswordList.filter((data) => {
                  return data.groupId ? _.includes(pjIds, data.groupId.id) : null;
                  });
                  filteredpass = filteredList.filter((passwordData) => {
                  return passwordData.password === ((sha256('Group' + pass + md5(String(passwordData.id)))).toString());
                  });
              }
            }
              resolve(filteredpass);
          }, err => console.error(err));
      });
    });
  }

  async callGroupPass() {
    const [applyText, cancel] = ['OK', false];
    return new Promise<any[]>((payResolve, reject) => {
      this.apiService.getGroupPassword({})
        .subscribe(pay => {
          payResolve(pay);
        }, (err => {
          console.error(err);
          this.dialog.openConfirmDialog({
            data: {
              applyText,
              cancel,
              text: 'エラーが発生しました。',
            }
          });
          reject();
        }));
    });
  }
}
