import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { isString } from 'lodash-es';
import { DialogService } from './dialog.service';

declare const XLSX: any; // lazy loading
declare const Encoding: any; // lazy loading

@Injectable({
  providedIn: 'root'
})

export class XLXSCSVService {

  constructor(
    private dialog: DialogService
  ) { }

  convertFileToJSON(file: File) {
    const xlsx = XLSX;
    const defer$ = new Subject<any[]>();
    const reader = new FileReader();
    const xlsxMatch = file.type.match(/^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet$/gi);
    const csvTsvMatch = file.type.match(/^text\/(csv|tab-separated-values)/gi);
    const rABS = reader.readAsBinaryString;

    if (!file || (!xlsxMatch && !csvTsvMatch && !file.name.match(/\.(xlsx|csv)$/gi))) {
      defer$.error('convertFileToJSON/invalid-filetype');
    }

    reader.onload = async () => {
      const data = rABS ? reader.result : new Uint8Array(reader.result as ArrayBuffer);
      let book;
      try {
        book = await xlsx.read(data, { type: rABS ? 'binary' : 'array', raw: true });
      } catch (e) {
        defer$.error('convertFileToJSON/fileread-failed');
      }
      const labels = [];
      const values = [];
      try {
        const sheet = book.Sheets[book.SheetNames[0]]; // sheet01
        const range = sheet['!ref'];
        const decodedRange = xlsx.utils.decode_range(range);
        for (let raw = decodedRange.s.r; raw <= decodedRange.e.r; raw++) {
          const obj = {};
          for (let cell = decodedRange.s.c; cell <= decodedRange.e.c; cell++) {
            const adr = xlsx.utils.encode_cell({ c: cell, r: raw });
            const vals = sheet[adr];
            if (raw === 0) {
              labels.push(this.encodeStr(vals.v));
            } else {
              obj[labels[cell]] = vals ? this.encodeStr(vals.v) : null;
            }
          }
          if (raw !== 0 && Object.getOwnPropertyNames(obj).some(key => obj[key])) {
            // tslint:disable-next-line: no-string-literal
            // obj['Instagramアカウント'] = [obj['Instagramアカウント1'], obj['Instagramアカウント2']];
            values.push(obj);
          }
        }
        defer$.next(values);
      } catch (err) {
        console.error(err);
        this.dialog.closeAll();
        this.fileLoadErrorDialog();
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

    return defer$;
  }

  encodeStr(str: string, target = 'UNICODE') {
    if (!Encoding || typeof str !== 'string') {
      return str;
    }
    const unitArray = str.split('').map(e => e.charCodeAt(0));
    const detect = Encoding.detect(unitArray);
    if (detect !== target) {
      const u8binary = Encoding.convert(unitArray, { to: target, from: 'detect' });
      return new TextDecoder().decode(Uint8Array.from(u8binary));
    } else {
      return str;
    }
  }

  stringToBool(val: string, nullable = false) {
    if (typeof val !== 'string') {
      return nullable ? null : false;
    }
    if (val.match(/^(true|TRUE|1)$/)) {
      return true;
    }
    return nullable ? (val.match(/^(false|FALSE|0)$/) ? false : null) : false;
  }

  stringToDate(datetime: string, nullable = false) {
    if (typeof datetime !== 'string' || !datetime.length) {
      return nullable ? null : new Date(NaN);
    }
    return new Date(datetime);
  }

  fixFullWidthNumber(num: string) {
    if (!isString(num)) {
      return num;
    }
    return num.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  }

  fixPhoneNumber(num: string) {
    if (!isString(num)) {
      return num;
    }
    let result = this.fixFullWidthNumber(num);
    result = result.replace(/[‐－―ー−]/g, '-');
    result = result.replace(/[＋]/g, '+');
    result = result.replace(/[\s]/g, ' ');
    return result;
  }

  getValidDate(val: string) {
    const date = new Date(val);
    return date.toString() === 'Invalid Date' ? null : date;
  }

  convertJSONToCsv(json: any): string {
    const xlsx = XLSX;
    const sheet = xlsx.utils.json_to_sheet(json);
    // console.log(sheet);
    const csv = xlsx.utils.sheet_to_csv(sheet);
    // console.log(csv);
    return csv;
  }

  downloadCSV(data: any[], fileName: string): void {
    const csvStrings = this.convertJSONToCsv(data);
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([bom, csvStrings], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', fileName);
    link.click();
    link.remove();
  }

  fileLoadErrorDialog() {
    this.dialog.openConfirmDialog({
      data: {
        text: 'ファイルの読み取りに失敗しました。ファイルの内容を再度ご確認ください。',
        apply: true,
        cancel: false
      }
    });
  }
}
