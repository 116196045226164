import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';

import { Member } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { environment } from '@lu/environment';


@Injectable()
export class EsMemberVirutualScrollStrategy extends DataSource<Member> {
  private dataStream = new BehaviorSubject<Member[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  private fetchedPages = new Set<number>();
  private subscription = new Subscription();
  public cachedList: Member[];
  public cachedExtraList: Member[];
  public totalMember: Member[];
  private queryTotal: number;
  private pageSize = 20;
  private hasNext = true;
  private pending = false;
  private baseQuery = { search: {} as any };
  private queryWithRange = { search: {} as any };

  constructor(
    private apiService: MatchingService,
  ) {
    super();
  }

  // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
  connect(collectionViewer: CollectionViewer) {
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i);
      }
    }));
    return this.dataStream;
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.searchNextMembers();
  }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return Array.isArray(this.cachedList) ? this.cachedList.length : 0;
  }

  public get hitLength(): number {
    return this.queryTotal;
  }

  /** With refresh order list. */
  searchMembers(query = { search: {} as any }) {
    this.pending = true;
    this.hasNext = true;
    this.cachedList = null;
    this.queryTotal = null;
    this.totalMember = null;
    this.baseQuery = cloneDeep(query);
    let isSortWithAiueo = false;
    let isSortWithInstagram = false;
    if (this.baseQuery.search._sort && this.baseQuery.search._sort === 'fullNameKana:ASC') {
      delete this.baseQuery.search._sort;
      isSortWithAiueo = true;
    }
    if (this.baseQuery.search._sort && this.baseQuery.search._sort === 'connection_instagrams_followersCount:DESC') {
      delete this.baseQuery.search._sort;
      isSortWithInstagram = true;
    }
    this.fetchedPages.clear();
    this.dataStream.next([]);
    this.fetchPage(0);
    this.apiService.getMember(this.baseQuery.search)
      .subscribe((result) => {
        this.pending = false;
        this.queryTotal = result.length;
        if (isSortWithAiueo) {
          result = result.sort((a, b) => {
            return (a.fullNameKana).localeCompare(b.fullNameKana, 'ja');
          });
        }
        if (isSortWithInstagram) {
          result = result.sort((a, b) => {
            const connectionInstagramsA: any = a.connection_instagrams[0];
            const connectionInstagramsB: any = b.connection_instagrams[0];
            let followerA;
            let followerB;
            if (connectionInstagramsA && connectionInstagramsA.followersCount) {
              followerA = connectionInstagramsA.followersCount ? connectionInstagramsA.followersCount : -1;
            } else {
              followerA = -1;
            }
            if (connectionInstagramsB && connectionInstagramsB.followersCount) {
              followerB = connectionInstagramsB.followersCount ? connectionInstagramsB.followersCount : -1;
            } else {
              followerB = -1;
            }
            return followerB - followerA;
          });
        }
        this.totalMember = result;
        this.cachedList = _.slice(result, 0, this.pageSize);
        this.cachedExtraList = _.slice(result, this.pageSize, result.length);
        this.dataStream.next(this.cachedList);
        if (this.queryTotal > this.cachedList.length) {
          this.hasNext = true;
        } else {
          this.hasNext = false;
        }
        if (!environment.production) {
          console.log('searchMember baseQuery:', this.baseQuery.search);
          console.log('searchMember queryWithRange:', this.queryWithRange.search);
          console.log('total:', this.queryTotal);
          console.log('cachedListSize:', this.cachedList.length);
        }
      }, err => {
        console.error(err);
        this.pending = false;
      });
  }

  /** Append results to order list */
  public async searchNextMembers(selectAll?: boolean) {
    if (!this.baseQuery
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.queryWithRange.search._start = this.cachedList.length;
    this.queryWithRange.search._limit = selectAll ? this.queryTotal - this.cachedList.length : this.pageSize;
    await this.delay(1500);
    this.cachedList.push(..._.slice(this.cachedExtraList, 0, this.pageSize));
    this.cachedExtraList = _.slice(this.cachedExtraList, this.pageSize, this.cachedExtraList.length);
    if (this.cachedList.length < this.queryTotal) {
      this.hasNext = true;
    } else {
      this.hasNext = false;
    }
    this.dataStream.next(this.cachedList);
    this.pending = false;
    if (!environment.production) {
      console.log('total:', this.queryTotal);
      console.log('cachedListSize:', this.cachedList.length);
      console.log('cachedExtraListSize:', this.cachedExtraList.length);
      console.log('hasNext:', this.hasNext);
    }
  }

  public async selectAllMembers() {
    if (!this.baseQuery
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.cachedList.push(...this.cachedExtraList);
    this.dataStream.next(this.cachedList);
    this.pending = false;
    if (!environment.production) {
      console.log('total:', this.queryTotal);
      console.log('cachedListSize:', this.cachedList.length);
      console.log('cachedExtraListSize:', this.cachedExtraList.length);
      console.log('hasNext:', this.hasNext);
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
