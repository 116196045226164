import { Component, OnInit, Renderer2, ViewChildren, ViewChild, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { firestore } from 'firebase/app';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { filter, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter as _filter, nth, map as _map, findIndex, find, isNil } from 'lodash-es';

import { FileChooserComponent } from '@lu/components/file-chooser/file-chooser.component';
import { Path } from '@lu/path';
import {
  Client,
  ProjectConfidential,
  Group,
  Project,
  NewProject,
  ServicePermissions,
  Candidate,
  Member,
  Reward,
  NewReward,
  GroupPermissions,
  NewProjectConfidential,
  Entry,
  ProjectKindMaster,
  CategoryMaster,
  BrandMaster,
  ProductMaster,
  AdminUser,
  NewCandidate,
} from '@lu/models';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { UserSearchDialogComponent } from '@lu/components/user-search-dialog/user-search-dialog.component';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';
import { CustomValidators } from '@lu/definitions/validators';
import { environment } from '@lu/environment';

const newId = (autoId = '') => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};

const contentMsg = `＜案件タイトル（例：●●ブランド新作ファンデお試し＆レポート）＞
◾️募集要項
・
・
・

◾️注意事項
・
・
・

◾️担当者の連絡先


※LINEなどのアプリ内ブラウザでこの画面を開いている場合、添付ファイルをひらけない場合があります。
その場合は、iPhoneであればSafari、AndroidであればChromeなどのブラウザで開きご確認いただけますようお願いします。`;

const notiMsg = `こんにちは。●●編集部の●●です。
ぜひご応募いただきたい企画についてご案内いたします。
今回は、●●ブランドの●●をお試しいただく企画です。

下記URLより、ハピコミュにログインし、詳細をご確認ください。

※応募締め切りは●／●（●）です。
※（その他、メッセージで送っておきたいことをご記入ください）

エントリーをお待ちしております。`;

@Component({
  selector: 'lu-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  providers: [
    DialogService
  ]
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @ViewChildren('thumbnail') thumbnails: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('filechooser') filechoosers: QueryList<FileChooserComponent>;

  @ViewChild('clientSelectionInput', { static: false }) clientSelectionInput: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocomplete', { static: false }) auto: MatAutocompleteTrigger;

  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  // form
  public orderDetailForm = new FormGroup({});

  public rewardForm = new FormGroup({
    amount: new FormControl(0),
    currency: new FormControl('JPY'),
    // リレーションが空でリクエストされないようにするために削除
    // project: new FormControl(null),
    // entries: new FormControl([]),
    // payments: new FormControl([]),
    // reports: new FormControl([])
  } as { [K in keyof NewReward]: AbstractControl });

  public secretConfidentialForm = new FormGroup({
    amount: new FormControl(0),
    currency: new FormControl('JPY'),
    project: new FormControl(null),
    remarks: new FormControl(null),
    created_at: new FormControl(null),
    updated_at: new FormControl(null)
  } as { [K in keyof NewProjectConfidential]: AbstractControl });

  public candidatesForm = new FormArray([]);

  public orderForm = new FormGroup({
    id: new FormControl(0),
    projectName: new FormControl(null, Validators.required),
    project_kind_master: new FormControl(null),
    allowDuplicatedEntry: new FormControl(true, Validators.required),
    recruitmentStartAt: new FormControl((moment().toDate()).toISOString(), Validators.required),
    recruitmentEndAt: new FormControl((moment().add(1, 'month')).toDate().toISOString(), Validators.required),
    eventStartAt: new FormControl((moment().toDate()).toISOString(), Validators.required),
    eventEndAt: new FormControl((moment().add(1, 'month')).toDate().toISOString(), Validators.required),
    remarks: new FormControl(contentMsg),
    messages: new FormControl(notiMsg),
    publishStatus: new FormControl(Project.PublishStatusEnum.Draft, Validators.required),
    publishModifiedAt: new FormControl((moment().toDate()).toISOString(), Validators.required),
    closedStatus: new FormControl(null),
    closedModifiedAt: new FormControl(null),
    status: new FormControl(Project.StatusEnum.Registered, Validators.required),
    sendUpdateNotification: new FormControl(false, Validators.required),
    brand_master: new FormControl(null),
    client: new FormControl(null, Validators.required),
    notificate: new FormControl(false),
    project_confidential: new FormControl(null),
    project_history: new FormControl(null),
    reward: new FormControl(null),
    created_at: new FormControl(moment().toDate().toISOString()),
    updated_at: new FormControl(moment().toDate().toISOString()),
    questionnaireURL: new FormControl(null, Validators.pattern(/https?:\/\/(www\.)?.+\./)),
    file1: new FormControl(null),
    file2: new FormControl(null),
    groups: new FormControl([]),
    members: new FormControl([]),
    category_masters: new FormControl([], Validators.minLength(0)),
    // ↓なくてもエントリーステータスは更新できる、あると排他処理でエラーになるのでそもそも受け取らない
    // entries: new FormControl([]),
    // payments: new FormControl([]),
    administrativeGroups: new FormControl([], CustomValidators.emptyOrLengthVaridator),
    product_masters: new FormControl([], Validators.required),
    // reports: new FormControl([]),
    candidates: new FormControl([])
  } as { [K in keyof Project]: AbstractControl });

  public publicConfidentialForm = new FormGroup({
    permission: new FormGroup({ level: new FormControl(0, Validators.required) }),
    data: new FormGroup({
      questionnaire: new FormGroup({
        name: new FormControl('アンケートURL'),
        value: new FormControl(null, Validators.pattern(/https?:\/\/(www\.)?.+\./)),
      })
    }),
    created: new FormGroup({
      at: new FormControl(firestore.FieldValue.serverTimestamp(), Validators.required),
      by: new FormControl(null),
    }),
    modified: new FormGroup({
      at: new FormControl(firestore.FieldValue.serverTimestamp(), Validators.required),
      by: new FormControl(null),
    }),
  } as { [K in keyof NewProjectConfidential]: AbstractControl });

  public filesForm = new FormArray([
    new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(0)
    }),
    new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(1)
    })
  ]);
  public servicePermissions: ServicePermissions | GroupPermissions;
  public order: Project;
  public reward: Reward | any;
  public entries: Entry;
  public secretConfidential: ProjectConfidential | any;
  public candidates: Array<Candidate | any>;
  public fixedCandidates: Record<string, Observable<Candidate & Partial<Member>>> = {};
  public members: Array<Member>;
  // public examination: Array<Examination & {_id: string}>; Not user but remain.
  public groupList: Array<Group>;
  public orderSegmentList: Array<ProjectKindMaster>;
  public orderCategoryList: Array<CategoryMaster>;
  public brandList: Array<BrandMaster>;
  public merchandiseList: Array<ProductMaster>;
  public readonly columnToDisplay = ['check', 'id', 'name', 'entrySchedule', 'statusChange', 'report'];
  private readonly path = Path;
  // States
  public noEventPeriod = false;
  public hasReward = false;
  public pending = false;
  public action: 'create' | 'update' = 'create';
  private onDestroy$ = new Subject();
  private admin: string;
  public candidateNum: any;
  public gpAdminWithAll = false;
  public routeTo = true;
  public acceptType = '.jpg, .png, .gif, .jpe, .jpeg, .JPG, .JPEG, .PNG, .GIF, .pdf, .mov, .mpeg, .mpg, .mp4, .webm, .ogg';
  public acceptFormats = ['png', 'jpg', 'gif', 'pdf', 'mov', 'mpeg', 'mpg',
                          'mp4', 'webm', 'ogg', 'jpe', 'jpeg', 'JPG', 'JPEG', 'PNG', 'GIF'];

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private renderer2: Renderer2,
    public http: HttpClient,
    private afAuth: AngularFireAuth,
    private dialog: DialogService,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.action = this.router.url.startsWith(this.path.order.create, 1) ? 'create' : 'update';
    this.getCurrentUser();
    this.addForms();
    this.subscribeCategories();
    this.subscribeSegments();
    this.subscribeMerchandise();
    this.subscribeBrands();
    this.getAllMembers();
    this.aRoute.data
      .pipe(filter(data => {
        if (this.isUpdate && _.isNil(data.order)) {
          alert('データを取得できませんでした。一覧画面に戻ります。');
          this.router.navigate([this.path.order.list]);
          return false;
        }
        return true;
      }))
      .subscribe(data => {
        this.servicePermissions = data.servicePermissions;
        const permiss = data.servicePermissions;
        if (permiss.length > 0) {
          if (permiss[0].role === AdminUser.RoleEnum.ServiceAdmin) {
            this.servicePermissions = serviceAdmin;
          } else if (permiss[0].role === AdminUser.RoleEnum.GroupAdmin) {
            this.servicePermissions = groupManager;
          } else {
            this.servicePermissions = groupEditor;
          }
        } else {
          this.servicePermissions = defaultPermissions;
        }
        this.groupList = data.groups;
        if (this.isUpdate) {
          this.gpAdminWithAll = false;
          this.order = data.order;
          this.reward = this.order.reward;
          this.candidates = this.order.candidates;
          this.secretConfidential = this.order.project_confidential;
          this.setDocumentsToForms();
          const adminGroups = this.orderForm.value.administrativeGroups;
          if (adminGroups && adminGroups.length === 0 &&
            (permiss[0].role === AdminUser.RoleEnum.GroupAdmin ||
             permiss[0].role === AdminUser.RoleEnum.GroupEditor)) {
            this.gpAdminWithAll = true;
          }
        }
      });
    if (this.isCreate && this.servicePermissions !== serviceAdmin) {
      this.orderForm.patchValue({
        administrativeGroups : this.groupList.map(g => g.id),
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  get isCreate() {
    return this.action === 'create';
  }

  get isUpdate() {
    return this.action === 'update';
  }

  get isClosed() {
    return this.order && this.order.status === Project.StatusEnum.Closed;
  }

  get isCanceled() {
    return this.order && this.order.closedStatus === Project.ClosedStatusEnum.Canceled;
  }

  get isPublished() {
    return this.order && this.order.publishStatus !== Project.PublishStatusEnum.Draft;
  }

  get today() {
    return new Date();
  }

  getDate(date: string) {
    return new Date(date);
  }

  setDate(ctrl: AbstractControl, date: Date) {
    ctrl.setValue(date.toISOString());
  }

  async getCurrentUser() {
    const { uid } = this.afAuth.auth.currentUser;
    const admin = () => {
      return new Promise<string>(resolver => {
        this.apiService.getAdminUser({ uid })
          .subscribe(doc => {
            if (doc.length > 0) {
              resolver(doc[0].id);
            }
          }, err => console.error(err));
      });
    };
    this.admin = await admin();
  }

  getAllMembers() {
    this.apiService.getMember({ _limit: -1, leaved: false })
      .subscribe(members => {
        this.members = members;
        const candidate = [];
        _.forEach(this.candidatesForm.value, (candidateData: any, index: number) => {
          const findMember = _.find(this.members, ['id', candidateData.member]);
          if (findMember) {
            candidateData.member = findMember;
          }
          else {
            let unshownCandidate = {
                id:candidateData.member,
                isHidden:true
            }
            candidateData.member = unshownCandidate;
          }
          candidate.push(candidateData);
        });
        this.candidatesForm.reset();
        this.candidates = candidate;
        this.setCurrentCandidatesToForm();
      }, err => {
        console.error(err);
      });
  }

  isCandidatesWithData(candidate: any) {
    if (!isNil(candidate)) {
      if (!isNil(candidate.member)) {
        if (!candidate.member.isHidden) {
          if (typeof candidate.member === 'object') {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  addForms() {
    this.orderDetailForm.addControl('order', this.orderForm);
    this.orderDetailForm.addControl('reward', this.rewardForm);
    this.orderDetailForm.addControl('secretConfidential', this.secretConfidentialForm);
    this.orderDetailForm.addControl('candidates', this.candidatesForm);
  }

  repaireProjectData() {
    this.order.groups = this.order.groups ?
      this.order.groups.map((list: any) => list.id) :
      [];

    this.order.category_masters = this.order.category_masters ?
      this.order.category_masters.map((list: any) => list.id) :
      [];

    this.order.product_masters = this.order.product_masters ?
      this.order.product_masters.map((list: any) => list.id) :
      [];

    if (this.order.project_kind_master) {
      const kind: any = this.order.project_kind_master;
      this.order.project_kind_master = kind.id;
    } else {
      this.order.project_kind_master = null;
    }

    if (this.order.brand_master) {
      const brand: any = this.order.brand_master;
      this.order.brand_master = brand.id;
    } else {
      this.order.brand_master = null;
    }

    if (this.order.administrativeGroups && this.order.administrativeGroups.length > 0) {
      const groupIdlist = this.groupList.map((group: any) => group.id);
      const adminGp: any[] = this.order.administrativeGroups.map((list: any) => list.id);
      const finalGroupData: any[] = [];
      _.forEach(adminGp, (gp: any) => {
        if (_.includes(groupIdlist, gp)) {
          finalGroupData.push(gp);
        }
      });
      this.order.administrativeGroups = finalGroupData;
    } else {
      this.order.administrativeGroups = [];
    }

    if (this.order.client) {
      const client: any = this.order.client;
      this.order.client = client.id;
    } else {
      this.order.client = null;
    }

    if (this.order.project_history) {
      const projectHistory: any = this.order.client;
      this.order.project_history = projectHistory.id;
    } else {
      this.order.project_history = null;
    }
  }

  setDocumentsToForms() {
    this.repaireProjectData();
    this.orderForm.patchValue(_.cloneDeep(this.order || {}));
    this.setCurrentCandidatesToForm();
    this.hasReward = this.reward.amount > 0 ? _.get(this.reward, 'amount') : false;
    if (this.reward !== null && this.reward !== undefined && this.reward) {
      if ('project' in this.reward) {
        this.reward.project = this.reward.project.id;
      }
      this.rewardForm.patchValue(_.cloneDeep(this.reward || {}));
    }
    if (this.secretConfidential !== null &&
      this.secretConfidential !== undefined &&
      this.secretConfidential) {
      this.secretConfidentialForm.patchValue(_.cloneDeep(this.secretConfidential || {}));
    }

    const displayImage = (async (file: any, i: number) => {
      if (file) {
        const isImage = file.mime.match(/image\/*/);
        const fileObj = !isImage ?
          new File([''], file.name, { type: file.mime }) : // dummy file
          await (async () => {
            return file.url;
          })();
        const desplayThumbnail = () => {
          const elementRef = this.thumbnails.toArray()[i];
          if (elementRef) {
            this.displayThumbnails(fileObj, elementRef.nativeElement);
          } else {
            requestAnimationFrame(desplayThumbnail);
          }
        };
        if (!this.filesForm.at(i)) {
          this.addFileForm({ data: fileObj, fileModel: file });
        }
        this.filesForm.at(i).patchValue({ data: fileObj, fileModel: file });
        if (isImage) {
          desplayThumbnail();
        }
      }
    });
    const file1 = this.orderForm.get('file1').value;
    const file2 = this.orderForm.get('file2').value;
    displayImage(file1, 0);
    displayImage(file2, 1);
    if (!environment.production) {
      console.log('repaired formdata:', this.orderForm);
    }
  }

  // set current candidates to form
  setCurrentCandidatesToForm() {
    // reset FormArray
    this.candidatesForm = new FormArray([]);
    _.forEach(this.candidates, candidate => {
      const clonedCandidateForm = this.generateCandidateForm();
      clonedCandidateForm.patchValue(candidate);
      this.candidatesForm.push(clonedCandidateForm);
    });
    this.candidateNum = this.candidatesForm.value.length;
  }

  subscribeCategories() {
    this.apiService.getMaster('category-masters', { _sort: 'order:ASC', parentMasterGroupId_null: false }).subscribe(
      categoryItem => {
        if (categoryItem) {
          this.orderCategoryList = categoryItem;
        }
      }, error => {
        console.error('Something was wrong in category list ====> ', error);
      }
    );
  }

  subscribeSegments() {
    this.apiService.getMaster('project-kind-masters', { _sort: 'order:ASC', parentMasterGroupId_null: false }).subscribe(
      pjKindItem => {
        if (pjKindItem) {
          this.orderSegmentList = pjKindItem;
        }
      }, error => {
        console.error('Something was wrong in project-kind-masters(item type) list ====> ', error);
      }
    );
  }

  subscribeBrands() {
    this.apiService.getMaster('brand-masters', { _sort: 'order:ASC', parentMasterGroupId_null: false }).subscribe(
      brandItem => {
        if (brandItem) {
          this.brandList = brandItem;
        }
      }, error => {
        console.error('Something was wrong in category list ====> ', error);
      }
    );
  }

  subscribeMerchandise() {
    this.apiService.getMaster('product-masters', { _sort: 'order:ASC', parentMasterGroupId_null: false }).subscribe(
      productItem => {
        if (productItem) {
          this.merchandiseList = productItem;
        }
      }, error => {
        console.error('Something was wrong in category list ====> ', error);
      }
    );
  }

  openSearchDialog() {
    const dialogRef = this.dialog.openComponentDialog(UserSearchDialogComponent, {
      height: '90vh',
      data: {
        order: this.orderForm.value,
        buttonMessage: '選択したメンバーをこの案件にエントリー可能にする',
        selectAll: true,
        _limit: -1
      }
    });
    const component: UserSearchDialogComponent = dialogRef.componentInstance;
    component.additinalSearchForm = new FormGroup({
      entryStatus: new FormControl('NULL')
    });
    component.additinalConditions = {};
    component.startAt = this.orderForm.get('eventStartAt').value;
    component.endAt = this.orderForm.get('eventEndAt').value;
    component.additinalConditions.entryStatus = {
      label: 'エントリー状況',
      type: 'radio',
      value: [
        {
          label: '指定なし',
          query: 'NULL'
        },
        {
          label: '期間内全ての日程に案件エントリー無し',
          query: 'noScheduled'
        },
        {
          label: '期間内に空きの日程あり',
          query: 'haveAfford'
        },
      ]
    };
    dialogRef.afterClosed()
      .pipe(filter(result => Array.isArray(result)))
      .subscribe((members: Member[]) => {
        console.log('dialog closed.', members);
        members.forEach(member => {
          this.appendNewCandidate(member);
        });
      });
  }

  generateCandidateForm(): AbstractControl {
    return new FormGroup({
      member: new FormControl(null),
      project: new FormControl(null),
      entry: new FormControl(null),
      passed: new FormControl(false),
      created_at: new FormControl(null),
    } as { [K in keyof NewCandidate]: AbstractControl });
  }

  appendNewCandidate(member: Member) {
    const exists = _.find(this.candidatesForm.value, ['member.id', member.id]);
    const ctrl = this.generateCandidateForm();
    if (exists) {
      return;
    }
    ctrl.patchValue({
      member,
      passed: true,

    });
    this.candidatesForm.push(ctrl);
    let count = 0;
    if (!isNil(this.candidatesForm.value)) {
      _.map(this.candidatesForm.value, can => {
        if (!isNil(can.member) &&
          typeof can.member === 'object' &&
          'id' in can.member) {
          count += 1;
        }
      });
      this.candidateNum = count;
    }
  }

  removeCandidate(id: number) {
    const pos = findIndex(this.candidatesForm.value, ['member.id', id]);
    if (pos < 0) {
      return;
    }
    this.candidatesForm.removeAt(pos);
    let count = 0;
    if (!isNil(this.candidatesForm.value)) {
      _.map(this.candidatesForm.value, can => {
        if (!isNil(can.member) &&
          typeof can.member === 'object'
          && 'id' in can.member) {
          count += 1;
        }
      });
      this.candidateNum = count;
    }
  }

  hasNotificateAdminGroup(guid: string) {
    const groups: string[] = this.orderForm.value.administrativeGroups;
    if (guid === null ) {
      return _.isEmpty(groups) ? true : false;
    }
    return _.isEmpty(groups) ? false : _.includes(groups, guid);
  }

  clientChanges(clients: Client[]) {
    const client = nth(clients, 0);
    const clientCtrl = this.orderForm.get('client');
    if (!client) {
      clientCtrl.reset();
    } else {
      clientCtrl.patchValue(client.id);
    }
  }

  brandChanges(brands: BrandMaster[]) {
    const brand = _.nth(brands, 0);
    const brandCtrl = this.orderForm.get('brand_master');
    if (!brand) {
      brandCtrl.reset();
    } else {
      const { id } = brand;
      brandCtrl.patchValue(id);
    }
  }

  notificateAdminGroupsChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const guid = event.source.value;
    const groupsCtrl = this.orderForm.get('administrativeGroups');
    const groups: string[] = groupsCtrl.value || [];
    const pos = _.indexOf(groups, guid);
    // All Groups
    if (guid === null && checked) {
      groupsCtrl.setValue(null);
      return;
    }
    // Add or remove from group.
    if (checked) {
      groups.push(guid);
      groupsCtrl.setValue(_.union(groups));
    } else if (pos >= 0) {
      groups.splice(pos, 1);
      groupsCtrl.setValue(groups);
    }
  }

  categoryChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const categoryId = event.source.value;
    const categoriesCtrl = this.orderForm.get('category_masters');
    const categories: string[] = categoriesCtrl.value || [];
    const pos = _.indexOf(categories, categoryId);
    // Add or remove from categories.
    if (checked) {
      categories.push(categoryId);
      categoriesCtrl.setValue(_.union(categories));
    } else if (pos >= 0) {
      categories.splice(pos, 1);
      categoriesCtrl.setValue(categories);
    }
  }

  merchandiseChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const merchandiseId = event.source.value;
    const merchandiseCtrl = this.orderForm.get('product_masters');
    const merchandise: string[] = merchandiseCtrl.value || [];
    const pos = _.indexOf(merchandise, merchandiseId);
    // Add or remove from categories.
    if (checked) {
      merchandise.push(merchandiseId);
      merchandiseCtrl.setValue(_.union(merchandise));
    } else if (pos >= 0) {
      merchandise.splice(pos, 1);
      merchandiseCtrl.setValue(merchandise);
    }
  }

  noEventPeriodChange(
    noEventPeriod: boolean,
    period = {
      startAt: moment().toDate(),
      endAt: moment().add(1, 'month').toDate()
    }
  ) {
    const eventStartCtrl = this.orderForm.get('eventStartAt');
    const eventEndCtrl = this.orderForm.get('eventEndAt');
    if (this.isUpdate) {
      const startAt = this.order.eventStartAt;
      const endAt = this.order.eventEndAt;
      period.startAt = startAt ? startAt : period.startAt;
      period.endAt = endAt ? endAt : period.endAt;
    }
    // clear value and validators
    if (noEventPeriod) {
      eventStartCtrl.clearValidators();
      eventStartCtrl.reset();
      eventEndCtrl.clearValidators();
      eventEndCtrl.reset();
    } else {
      eventStartCtrl.setValue(period.startAt);
      eventEndCtrl.setValue(period.endAt);
      eventStartCtrl.setValidators(Validators.required);
      eventEndCtrl.setValidators(Validators.required);
    }
    eventStartCtrl.updateValueAndValidity();
    eventEndCtrl.updateValueAndValidity();
    this.noEventPeriod = noEventPeriod;
  }

  async hasRewardChange(hasReward: boolean, amount = 0) {
    const rewardAmountCtrl = this.rewardForm.get('amount');
    if (hasReward) {
      rewardAmountCtrl.setValidators([Validators.required, Validators.minLength(0)]);
      rewardAmountCtrl.setValue(amount);
      rewardAmountCtrl.updateValueAndValidity();
    } else {
      rewardAmountCtrl.clearValidators();
      rewardAmountCtrl.reset(null);
    }
    this.hasReward = hasReward;
  }

  autoCompleteGetWith(field: string) {
    return (value: any) => value ? value[field] : value;
  }

  addFileForm(value: { data: File, fileModel?: any } = { data: null }) {
    const currentLength = this.filesForm.controls.length;
    const ctrl = new FormGroup({
      data: new FormControl(value.data || null),
      fileModel: new FormControl(value.fileModel || null),
      prevIndex: new FormControl(currentLength),
    });
    this.filesForm.push(ctrl);
  }

  fileSelectChange(file: File, index: number) {
    const ctrl = this.filesForm.at(index);
    const maxSize = 50 * 1024 ** 2;
    if (file instanceof File && file.size > maxSize) {
      this.filechoosers.toArray()[index].removeFile();
      return alert('ファイルサイズが50MBを越えています');
    }
    // Attach file
    if (file instanceof File) {
      ctrl.patchValue({ data: file, fileModel: null });
    } else {
      ctrl.patchValue({ data: null, fileModel: null });
    }
    ctrl.markAsDirty();
  }

  async displayThumbnails(file: any, cover: HTMLElement) {
    if (file instanceof File && file.type.match(/image\/.*/)) {
      const component = new FileChooserComponent();
      const url = await component.convertToDataURL(file);
      this.renderer2.setStyle(cover, 'display', 'block');
      this.renderer2.setStyle(cover, 'background-image', `url(${url})`);
    } else if (typeof file === 'string' && file) {
      this.renderer2.setStyle(cover, 'display', 'block');
      this.renderer2.setStyle(cover, 'background-image', `url(${file})`);
    } else {
      this.renderer2.setStyle(cover, 'display', 'none');
      this.renderer2.removeStyle(cover, 'background-image');
    }
  }

  /**
   * Upload files if some new file attached.
   */
  async prepareFiles() {
    const fileList: File[] = [];
    this.filesForm.controls.forEach(async (ctrl, i) => {
      const data: File = ctrl.value.data;
      let fileData: File;
      if (data !== null) {
        if (data.size === 0) {
          fileData = null;
        } else if (data.size > 0) {
          fileData = data;
        }
      } else {
        fileData = null;
        if (this.orderForm.get('file1').value || this.orderForm.get('file2').value) {
          switch (i) {
            case 0:
              this.orderForm.patchValue({
                file1: null
              });
              break;
            case 1:
              this.orderForm.patchValue({
                file2: null
              });
              break;
            default:
              break;
          }
        }
      }
      fileList.push(fileData);
    });
    return fileList;
  }

  async checkForCandidates(candidates) {
    return new Promise((resolver) => {
      const candis = candidates;
      let candiNum = candidates.length;
      const product = this.orderForm.value.product_masters;
      const brand = this.orderForm.value.brand_master;
      const message = new Array();
      const errMsg  = new Array();
      const retrundata = [];
      _.forEach(candis, async candi => {
        message.length = 0;
        const routePj = {
          allowDuplicatedEntry: false,
          _limit : -1
        };
        const getpjlist = () => {
          return new Promise((pjResolve) => {
            this.apiService.getProject(routePj)
              .subscribe(pjs => {
                _.filter(pjs, ['candidates.member', candi.member.id]);
                pjResolve(pjs);
              });
          });
        };
        const pjlist: any = await getpjlist();

        const pjIds = pjlist.map(ma => ma ? ma.id : null);
        const routeData = {
          member: candi.member.id,
          status_in: ['completed', 'decided'],
          _limit: -1
        };
        const getentrylist = () => {
          return new Promise((pjResolve) => {
            this.apiService.getEntries(routeData)
              .subscribe(entries => {
                const filterentry = _.forEach(entries , (et: any) => {
                  if (et.project && _.includes(pjIds, et.project.id)) {
                    return et;
                  }
                });
                pjResolve(filterentry);
              });
          });
        };
        const entrylist: any = await getentrylist();
        const entrypj: any =  pjlist.filter(o1 => entrylist.some(o2 => {
          if (o1 && o2 && o2.project &&  o1.id === o2.project.id) {
            return true;
          } else {
            return false;
          }
        }));
        const filteredpjlist = () => {
          return new Promise((pjResolve) => {
            message.length = 0;
            _.forEach(entrypj, pj => {
              const pdmaster = pj.product_masters.map(ma => ma.id);
              const result = pdmaster.filter(o1 => product.some(o2 => o1 === o2));
              const bdmaster: BrandMaster = pj.brand_master;
              const brandresult = (bdmaster && brand) ? bdmaster.id === brand ? pj.brand_master : null : null;

              const startevent = new Date(pj.eventStartAt);
              startevent.setHours(0, 0, 0, 0);

              const endevent = new Date(pj.eventEndAt);
              endevent.setHours(0, 0, 0, 0);

              const currentStart = new Date(this.orderForm.value.eventStartAt);
              currentStart.setHours(0, 0, 0, 0);

              const currentEnd = new Date(this.orderForm.value.eventEndAt);
              currentEnd.setHours(0, 0, 0, 0);

              const samestart = moment(endevent).isSame(currentEnd);
              const startbefore = moment(currentStart).isBefore(startevent);
              const startafter = moment(currentStart).isAfter(startevent);
              const startbetween = currentStart <= endevent && currentStart >= startevent;

              const sameend = moment(endevent).isSame(currentEnd);
              const endbefore = moment(currentEnd).isBefore(endevent);
              const endafter = moment(currentEnd).isAfter(endevent);
              const endbetween = currentEnd <= endevent && currentEnd >= startevent;
              const filtered = (startbefore && endbetween) || (samestart && sameend) ||
                                (startbetween && endafter) || (startbefore && endafter) || (startbetween && endbetween);

              if ((result.length > 0 || brandresult !== null) && filtered === true && entrylist.length > 0) {
                message.push(candi.member.fullName + 'さんは競合案件に本決定しているためメンバー指定できませんでした。');
                pjResolve(message);
              }
            });
            candiNum = candiNum - 1 ;
            pjResolve(message);
          });
        };
        const ppj: any = await filteredpjlist();
        _.forEach(ppj, msg => { errMsg.push(msg); });
        if (ppj.length > 0) {
          const pos = findIndex(this.candidatesForm.value, ['member.id', candi.member.id]);
          if (pos < 0) {
            return;
          }
          this.candidatesForm.removeAt(pos);
          this.candidateNum = this.candidatesForm.value.length;
          this.orderForm.patchValue({
            candidates: (_.cloneDeep(this.candidatesForm.value)),
          });
          retrundata.push(true);
          if (candiNum === 0) {
            resolver(retrundata);
          }
        } else {
          retrundata.push(false);
          if (candiNum === 0 || this.candidatesForm.value.length === 0) {
            resolver(retrundata);
          }
        }
        if (candiNum === 0 && errMsg.length > 0) {
          this.openInvalidErrorDialog(errMsg);
        }
      });
    });
  }

  openInvalidErrorDialog(err: any) {
    this.dialog.closeAll();
    let html = '';
    if (Array.isArray(err)) {
      _.forEach(err as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        if (err[i] !== err[i + 1]) {
          html += `<div>${message}</div>`;
        }
      });
    } else {
      html += `<br /><div>メンバー は ありません。</div>`;
    }
    let dialogRef;
    dialogRef = this.dialog.openConfirmDialog({
      panelClass: ['mat-dialog-scrollable'],
      minWidth: '600px',
      maxHeight: '90vh',
      autoFocus: false,
      data: {
        html,
        cancel: false
      }
    });
    dialogRef.afterClosed().subscribe(async () => {
      await this.sleep(1000);
      this.router.navigate([this.path.order.list]);
    });
  }

  async createOrder() {
    const fileList = await this.prepareFiles();
    const reward = this.rewardForm.value;
    const candis = this.candidatesForm.value;
    const allowduplicate = this.orderForm.value.allowDuplicatedEntry;
    this.orderForm.patchValue({
      reward,
      candidates: (_.cloneDeep(this.candidatesForm.value)),
      project_confidential: this.secretConfidentialForm.value
    });
    if (this.orderForm.controls.questionnaireURL.value === '') {
      this.orderForm.controls.questionnaireURL.setValue(null);
    }
    let includeCandi;
    if (this.candidatesForm.value.length > 0 && allowduplicate === false) {
      const checkCandidate: any = await this.checkForCandidates(candis);
      includeCandi = _.includes(checkCandidate, true);
    }
    if (includeCandi === false) {
      this.orderForm.value.candidates = this.candidatesForm.value;
    }
    const body = new FormData();
    body.append('data', JSON.stringify(this.orderForm.value));
    body.append('files.file1', fileList[0]);
    body.append('files.file2', fileList[1]);

    JSON.stringify(body);
    this.apiService.createProject(body).subscribe(
      data => data ? true : false, error => console.error(error));
    if (!(includeCandi) || includeCandi === false) {
      // this.pending = true;
      this.routeTo = true;
    } else {
      this.pending = false;
      this.routeTo = false;
    }
  }

  async updateOrder() {
    const existCandi: any = this.order.candidates;
    const currentCandi: any = this.candidatesForm.value;
    const projectId = this.orderForm.get('id').value;
    const allowduplicate = this.orderForm.value.allowDuplicatedEntry;
    const fileList = await this.prepareFiles();
    this.rewardForm.value.amount = this.rewardForm.get('amount').value ? this.rewardForm.value.amount : 0;
    const reward = this.rewardForm.value;
    if (this.orderForm.controls.questionnaireURL.value === '') {
      this.orderForm.controls.questionnaireURL.setValue(null);
    }
    this.orderForm.patchValue({
      reward,
      candidates: (_.cloneDeep(this.candidatesForm.value)),
      project_confidential: this.secretConfidentialForm.value
    });
    const result = currentCandi.filter(o1 => existCandi.some(o2 => o1.member.id === o2.member));
    const newCandis = currentCandi.filter(el => {
      return result.indexOf(el) === -1;
   });
    let includeCandi;
    if (newCandis.length > 0 && allowduplicate === false) {
      const checkCandidate: any = await this.checkForCandidates(newCandis);
      includeCandi = _.includes(checkCandidate, true);
    }
    if (includeCandi === false) {
      this.orderForm.value.candidates = this.candidatesForm.value;
    }
    const body = new FormData();
    body.append('data', JSON.stringify(this.orderForm.value));
    body.append('files.file1', fileList[0]);
    body.append('files.file2', fileList[1]);
    JSON.stringify(body);
    this.apiService.updateProject(
      projectId,
      body
    ).subscribe(
      doc => {
        const history = {
          project: doc.id,
          projectName: doc.projectName,
          timestamp: new Date().toISOString() as any,
          author: this.admin
        };
        if (doc.project_history) {
          const prohistory: any = doc.project_history;
          this.apiService.updateHistory(prohistory.id, history).subscribe(
            data => console.log('updated history'), error => console.error(error));
        } else {
          this.apiService.createHistory(history).subscribe(
            data => console.log('add new history'), error => console.error(error));
        }
      }, err => console.error(err)
    );
    if (!(includeCandi) || includeCandi === false) {
      this.pending = false;
      this.routeTo = true;
    } else {
      this.pending = false;
      this.routeTo = false;
    }
  }

  registerDraftOrder() {
    const currentOrder = (this.orderForm.value as Project);
    if (currentOrder.publishStatus !== Project.PublishStatusEnum.Draft) {
      this.orderForm.patchValue({
        status: Project.StatusEnum.Registered,
        publishStatus: Project.PublishStatusEnum.Draft,
        publishModifiedAt: new Date(Date.now()).toISOString() as any,
        publishModifiedBy: null,
        closedModifiedAt: null,
        closedModifiedBy: null,
        closedStatus: null,
      });
    }
    this.registerOrder();
  }

  publishOrder() {
    const currentOrder = () => (this.orderForm.value as Project);
    // nullだったら無期限の扱いとする
    const isFuture = (date: Date | null) => date && date.valueOf() - Date.now() > 0;
    const isPast = (date: Date | null) => date && date.valueOf() - Date.now() < 0;
    const withinPeriod = (startAt: Date, endAt: Date): boolean => {
      if (isFuture(startAt) || isPast(endAt)) {
        return false;
      }
      if ((!startAt || isPast(startAt))
        && (!endAt || isFuture(endAt))) {
        return true;
      }
    };
    // Add published timestamp
    if (currentOrder().publishStatus !== Project.PublishStatusEnum.Public) {
      this.orderForm.patchValue({
        status: Project.StatusEnum.Registered,
        publishStatus: Project.PublishStatusEnum.Public,
        publishModifiedAt: new Date(Date.now()).toISOString() as any,
        publishModifiedBy: this.admin,
        closedModifiedAt: null,
        closedModifiedBy: null,
        closedStatus: null,
      });
    }
    // Assign status
    // Recruitment period is future.
    if (isFuture(new Date(currentOrder().recruitmentStartAt))) {
      this.orderForm.patchValue({
        status: Project.StatusEnum.Registered,
        closedModifiedAt: null,
        closedModifiedBy: null,
        closedStatus: null,
      });
    }
    // Current is recruiting period.
    if (withinPeriod(new Date(currentOrder().recruitmentStartAt),
      new Date(currentOrder().recruitmentEndAt))) {
      this.orderForm.patchValue({
        status: Project.StatusEnum.Recruiting,
      });
    }
    // Recruitment period has been past.
    if (isPast(new Date(currentOrder().recruitmentEndAt))) {
      this.orderForm.patchValue({
        status: Project.StatusEnum.Closed,
        closedModifiedAt: new Date(Date.now()).toISOString() as any,
        closedModifiedBy: this.admin,
        closedStatus: null,
      });
    }
    this.registerOrder();
  }

  sleep(time = 1000) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

  async registerOrder() {
    const isDraft = (this.orderForm.value as Project).publishStatus === Project.PublishStatusEnum.Draft;
    if ((this.isCreate && !isDraft) || (!this.isCreate && !isDraft && !this.isPublished)) {
      let content = '<div class="inline-warning">エントリー開始日時前です。</div>';
      const isFuture = (date: Date | null) => date && date.valueOf() - Date.now() > 0;
      if (isFuture(new Date(this.orderForm.value.recruitmentStartAt))) {
        content += `<div>案件を登録し、メンバーに即時案内メールを送信してよろしいですか？</div>`;
      } else {
        content = '案件を登録し、メンバーに即時案内メールを送信してよろしいですか？'
      }
      const confirmed: boolean = await new Promise(resolve => {
        this.dialog.openConfirmDialog({
          data: {
            html: content,
            applyText: 'はい',
            cancelText: 'いいえ',
            applyButtonColor: 'warn',
            cancelButtonColor: 'primary',
          },
          panelClass: ['inverted-button-color-dialog'],
        }).afterClosed()
          .subscribe(result => resolve(!!result));
      });
      if (!confirmed) { return }
    }
    const data: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const actionText = this.isCreate ? '登録' : '更新';
    this.pending = true;
    try {
      data.text = isDraft ?
        `下書きを${actionText}しています...` :
        `案件を${actionText}しています...`;
      if (this.isCreate) {
        await this.sleep(1500);
        await this.createOrder();
      } else {
        await this.updateOrder();
        await this.sleep(1000);
      }

      data.text = isDraft ?
        `下書きを${actionText}しました。` :
        `案件を${actionText}しました。`;
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      if (this.routeTo === true) {
        this.router.navigate([this.path.order.list]);
      }
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert(
        isDraft ?
          `下書きを${actionText}できませんでした。` :
          `案件を${actionText}できませんでした。`
      );
    } finally {
      this.pending = false;
    }
  }

  async closeOrder() {
    const confirmed: boolean = await new Promise(resolve => {
      this.dialog.openConfirmDialog({
        data: {
          text: '募集中の案件を中止します。よろしいですか？',
          applyText: '中止',
          cancelText: '戻る',
          applyButtonColor: 'warn',
          cancelButtonColor: 'primary',
        },
        panelClass: ['inverted-button-color-dialog'],
      }).afterClosed()
        .subscribe(result => resolve(!!result));
    });

    if (!confirmed) {
      return;
    }
    const data: LodingDialogData = {
      text: '案件の中止処理をしています...',
      color: 'warn'
    };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });

    try {
      this.pending = true;
      this.orderForm.patchValue({
        status: Project.StatusEnum.Closed,
        closedModifiedAt: new Date(Date.now()).toISOString() as any,
        closedModifiedBy: this.admin,
        closedStatus: Project.ClosedStatusEnum.Canceled,
      });

      await this.updateOrder();
      await this.sleep(1000);
      data.text = '案件を中止しました。';
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      this.router.navigate([this.path.order.list]);
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert('案件を中止できませんでした。');
    } finally {
      this.pending = false;
    }
  }

  openOrderRePublishDialog() {
    this.dialog.openConfirmDialog({
      data: {
        text: '案件の再募集を開始します。よろしいですか？',
        applyText: 'はい',
        cancelText: '戻る',
        applyButtonColor: 'warn',
        cancelButtonColor: 'primary',
      },
      panelClass: ['inverted-button-color-dialog'],
    }).afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => this.publishOrder());
  }

  log() {
    console.log('orderDetailForm', this.orderDetailForm);
  }
}
