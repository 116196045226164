import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';

export interface EmailUpdatingData {
  newEmail?: string;
  html?: string;
  text?: string;
  apply?: boolean;
  cancel?: boolean;
  applyText?: string;
  cancelText?: string;
  applyButtonColor?: ThemePalette;
  cancelButtonColor?: ThemePalette;
}

@Component({
  selector: 'lu-member-email-update-dialog',
  templateUrl: './member-email-update-dialog.component.html',
  styleUrls: ['./member-email-update-dialog.component.scss']
})
export class MemberEmailUpdateDialogComponent implements OnInit {
  public newEmailForm = new FormGroup({
    newEmail: new FormControl(null, [
      Validators.email,
      Validators.required,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)]),
  });
  constructor(
    public dialogRef: MatDialogRef<MemberEmailUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailUpdatingData
  ) {
    if (typeof this.data !== 'object') {
      this.data = {};
    }
    if (typeof this.data.apply !== 'boolean') {
      this.data.apply = true;
    }
    if (typeof this.data.cancel !== 'boolean') {
      this.data.cancel = true;
    }
  }
  ngOnInit() {
  }

}
