import { Component, ElementRef, OnInit, Renderer2, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatCheckboxChange } from '@angular/material/checkbox';

import * as _ from 'lodash';
import { get, find, filter as _filter, defaultTo, some, isArray, isNil, isEmpty, difference, update, cloneDeep } from 'lodash-es';
import { Subject, merge, of, BehaviorSubject } from 'rxjs';
import { map, takeUntil, filter, delayWhen, tap } from 'rxjs/operators';

import { Path } from '@lu/path';
import { DialogService } from '@lu/services/dialog.service';
import { FileChooserComponent } from '@lu/components/file-chooser/file-chooser.component';
import { LoadingDialogComponent, LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';
import { ValidationRules } from '@lu/definitions/validators';
import {
  Group,
  Member,
  ServicePermissions,
  ConnectionTwitter,
  ConnectionInstagram,
  ConnectionTikTok,
  ConnectionYoutube,
  SecurityClearance,
  GroupPermissions,
  Address,
  BankAccount,
  MemberConfidential,
  CategoryMaster,
  IndustryMaster,
  OccupationMaster,
  JobMaster,
  MemberStatusMaster,
  ProjectKindMaster,
  Project,
  TagMaster,
  MemberConfidentialTagMaster,
  Entry,
} from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { MemberEmailUpdateService } from '@lu/services/member-email-update.service';
import { MatDialogRef } from '@angular/material';

const validationRules = new ValidationRules();
const memberRules = validationRules.member();
const URLValidator = () => Validators.pattern(memberRules.url);
const TwitterNameValidator = () => Validators.pattern(memberRules.twitter);
const InstagramNameValidator = () => Validators.pattern(memberRules.instagram);
const TiktokNameValidator = () => Validators.pattern(memberRules.tiktok);
const newId = (autoId = '', size = 20) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < size; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};

@Component({
  selector: 'lu-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @ViewChildren('thumbnail') thumbnails: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('filechooser') filechoosers: QueryList<FileChooserComponent>;
  public servicePermissions: ServicePermissions | GroupPermissions;
  public serviceAdmin = serviceAdmin;
  public groupAdmin = groupManager;
  public groupEditor = groupEditor;
  public permittedGroups: any;
  /** formGroup for summarize forms status. */
  public memberDetailForm = new FormGroup({});
  public membersForm = new FormGroup({
    uid: new FormControl(newId(), Validators.required),
    foreignKey: new FormControl(null),
    fullName: new FormControl(null, Validators.required),
    order: new FormControl(0),
    fullNameKana: new FormControl(null, [Validators.required, Validators.pattern(memberRules.fullNameKana)]),
    displayName: new FormControl(null),
    address: new FormControl(null),
    email1: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(memberRules.email),
    ]),
    email2: new FormControl(null, [
      Validators.email,
      Validators.pattern(memberRules.email),
    ]),
    phoneNumber1: new FormControl(null, Validators.pattern(memberRules.phoneNumber)),
    phoneNumber2: new FormControl(null, Validators.pattern(memberRules.phoneNumber)),
    gender: new FormControl(null),
    married: new FormControl(null),
    birthDay: new FormControl(null),
    uncertainAge: new FormControl(false),
    height: new FormControl(null, Validators.minLength(0)),
    weight: new FormControl(null, Validators.minLength(0)),
    occupation_master: new FormControl(null),
    industry_master: new FormControl(null),
    job_master: new FormControl(null),
    hasChildren: new FormControl(null),
    children: new FormControl([]),
    registeredDate: new FormControl(new Date()),
    bank_account: new FormControl(null),
    mainSiteName: new FormControl(null),
    mainSiteURL: new FormControl(null, URLValidator()),
    connection_twitter: new FormControl(null),
    connection_instagrams: new FormControl([]),
    connection_tik_tok: new FormControl(null),
    connection_youtube: new FormControl(null),
    otherSiteName1: new FormControl(null),
    otherSiteName2: new FormControl(null),
    otherSiteName3: new FormControl(null),
    otherSiteURL1: new FormControl(null, URLValidator()),
    otherSiteURL2: new FormControl(null, URLValidator()),
    otherSiteURL3: new FormControl(null, URLValidator()),
    member_status_master: new FormControl(null, Validators.required),
    groups: new FormControl([], Validators.required),
    tag_masters: new FormControl([]),
    member_confidential: new FormControl(null),
    publishStartAt: new FormControl(new Date()),
    catchphrase: new FormControl(null, Validators.maxLength(50)),
    profile: new FormControl(null, Validators.maxLength(200)),
    remarks1: new FormControl(null),
    remarks2: new FormControl(null),
    summary: new FormControl(null),
    image1: new FormControl(null),
    image2: new FormControl(null),
    image3: new FormControl(null),
    image4: new FormControl(null),
    leaved: new FormControl(false),
    lineDisplayName: new FormControl(null),
    lineId: new FormControl(null),
  });
  public childrenForm = new FormArray([]);
  public hasBankAccount = new FormControl(false);
  public addressForm = new FormGroup({
    postalCode: new FormControl(null, Validators.pattern(memberRules.postalCode)),
    address: new FormControl(null),
    prefectureCode: new FormControl(null),
    member: new FormControl(null),
  });
  public twitterForm = new FormGroup({
    id: new FormControl(null),
    idOfResponse: new FormControl(0),
    idStr: new FormControl(null),
    name: new FormControl(null),
    screenName: new FormControl(null, TwitterNameValidator()),
    protected: new FormControl(null),
    verified: new FormControl(null),
    followersCount: new FormControl(0),
    friendsCount: new FormControl(0),
    member: new FormControl(null),
    profileImageURLHTTPS: new FormControl(null),
  });
  public instagramForm = new FormArray([
    new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(null),
      username: new FormControl('', InstagramNameValidator()),
      followersCount: new FormControl(0),
      accountType: new FormControl(null),
      member: new FormControl(null),
      order: new FormControl(0)
    }),
    new FormGroup({
      id: new FormControl(null),
      idOfResponse: new FormControl(null),
      username: new FormControl(''),
      followersCount: new FormControl(0),
      member: new FormControl(null),
      accountType: new FormControl(null),
      order: new FormControl(1)
    })
  ]);
  public tiktokForm = new FormGroup({
    id: new FormControl(null),
    userId: new FormControl(null),
    uniqueId: new FormControl(null, TiktokNameValidator()),
    nickName: new FormControl(null),
    fans: new FormControl(0),
    hearts: new FormControl(0),
    member: new FormControl(null),
    verified: new FormControl(false),
  });
  public youtubeForm = new FormGroup({
    id: new FormControl(null),
    idOfResponse: new FormControl(null),
    viewCount: new FormControl(0),
    commentCount: new FormControl(0),
    member: new FormControl(null),
    subscriberCount: new FormControl(0),
  });
  public memberConfidentialForm = new FormGroup({
    remarks: new FormControl(null),
    member_confidential_tag_masters: new FormControl([]),
  });
  public imagesForm = new FormArray([
    new FormGroup({ data: new FormControl(null), fileModel: new FormControl(null), prevIndex: new FormControl(0) }),
    new FormGroup({ data: new FormControl(null), fileModel: new FormControl(null), prevIndex: new FormControl(1) }),
    new FormGroup({ data: new FormControl(null), fileModel: new FormControl(null), prevIndex: new FormControl(2) }),
    new FormGroup({ data: new FormControl(null), fileModel: new FormControl(null), prevIndex: new FormControl(3) }),
  ]);
  public bankAccountForm = new FormGroup({
    id: new FormControl(null),
    bankName: new FormControl(null),
    bankAccountNumber: new FormControl(null),
    bankBranchName: new FormControl(null),
    bankAccountOwnerName: new FormControl(null),
    bankAccountOwnerNameKana: new FormControl(null),
    member: new FormControl(null),
  });

  public providerLINE: string;
  public member: Member;
  public address: Address | any;
  public bankAccount: BankAccount | any;
  public secretConfidential: MemberConfidential;
  public groupList: Array<Group>;
  public categoryList: Array<CategoryMaster>;
  public occupationList: Array<OccupationMaster>;
  public industryList: Array<IndustryMaster>;
  public jobList: Array<JobMaster>;
  public memberSegmentList: Array<MemberStatusMaster>;
  public orderSegmentList: Array<ProjectKindMaster>;
  public memberTagList: Array<TagMaster>;
  public tagGroupList: Array<Group & { id: number }>;
  public confidentialTagGroupList: Array<MemberConfidentialTagMaster>;
  public confidentialTagList: Array<MemberConfidentialTagMaster>;
  public worksHeader = ['orderNumber', 'orderName', 'segment', 'categories', 'status', 'eventPeriod'];
  public works: any;
  public clearance = SecurityClearance;
  public genderList = [
    { value: null, label: null },
    { value: Member.GenderEnum.Male, label: '男性' },
    { value: Member.GenderEnum.Female, label: '女性' },
    { value: 'unanswered', label: '未回答' }, // To DO -> Define enum for unanswered.
  ];
  private readonly path = Path;
  public action: 'create' | 'update' = 'create';
  public pendingCount = 0;
  public sendWelcomeMail = false; // v1.0.0
  public useOpenPeriodStartAt = false;
  private onDestroy$ = new Subject();
  public acceptType = '.png, .jpg, .gif, .jpe, .jpeg, .JPG, .JPEG, .PNG, .GIF';
  public acceptFormats = ['png', 'jpg', 'gif', 'jpe', 'jpeg', 'JPG', 'JPEG', 'PNG', 'GIF'];
  private maxSize = 10;
  public pending = false;
  public cachedList: Project[];
  public cachedExtraList: Project[];
  public totalMember: Project[];
  private queryTotal: number;
  private hasNext = true;
  private dataStream = new BehaviorSubject<Project[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  public baseQuery = {} as any;
  private queryWithRange = { search: {} as any };
  public statusList = [
    {
      label: 'エントリー中',
      value: Entry.StatusEnum.Entried,
    },
    {
      label: '内定',
      value: Entry.StatusEnum.Offered,
    },
    {
      label: '仮決定',
      value: Entry.StatusEnum.Approved,
    },
    {
      label: '本決定',
      value: Entry.StatusEnum.Decided,
    },
    {
      label: '完了',
      value: Entry.StatusEnum.Completed,
    },
    {
      label: '見送り',
      value: Entry.StatusEnum.Canceled,
    },
  ];

  constructor(
    private title: Title,
    private aRoute: ActivatedRoute,
    private router: Router,
    private afAuth: AngularFireAuth,
    private renderer2: Renderer2,
    private dialog: DialogService,
    private emailUpdateService: MemberEmailUpdateService,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.action = this.router.url.startsWith(this.path.user.create, 1) ? 'create' : 'update';
    this.appendForms();
    this.setTitle();
    this.subscribeServicePermissions();
    this.subscribeTagGroups();
    this.subscribeTags();
    this.confidentialTagGroups();
    this.confidentialTag();
    this.subscribeCategories();
    this.subscribeMemberSegments();
    this.subscribeOrderSegments();
    this.subscribeOccupations();
    this.subscribeIndustries();
    this.subscribeJobs();
    this.watchChildrenStateChange();
    this.aRoute.data
      .subscribe((data: {
        groups: Array<Group>,
        member: Member,
        confidentials: MemberConfidential,
        password: any
      }
      ) => {
        console.log(data);
        this.groupList = data.groups;
        if (this.isUpdate) {
          this.member = data.member;
          // set JST time
          if (this.member.birthDay) {
            this.member.birthDay = this.setJST(this.member.birthDay);
          } else {
            this.member.birthDay = null;
          }
          if (this.member.children.length > 0) {
            this.member.children = _.map(this.member.children, (children: any) => {
              if (children.birthDay) {
                children.birthDay = this.setJST(children.birthDay);
              } else {
                children.birthDay = null;
              }
              return children;
            });
          }
          this.secretConfidential = data.confidentials;
          this.address = this.member.address ? this.member.address : null;
          this.bankAccount = this.member.bank_account ? this.member.bank_account : null;
          this.setConnectionDocumentsToForms();
          this.repairMemberData();
          this.setDocumentsToForms();
          this.getMemberWorks();
          this.getPersonalData();
          this.assignOrderChange();
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  setTitle() {
    const currentTitle = this.title.getTitle();
    const titleSuffix = ' | ' + (this.isCreate ? '新規ユーザー作成' : 'ユーザー編集');
    this.title.setTitle(currentTitle.replace(/(\s\|\s.+)?$/, titleSuffix));
  }

  appendForms() {
    this.memberDetailForm.addControl('image', this.imagesForm);
    this.memberDetailForm.addControl('member', this.membersForm);
    this.memberDetailForm.addControl('address', this.addressForm);
    this.memberDetailForm.addControl('twitter', this.twitterForm);
    this.memberDetailForm.addControl('instagram', this.instagramForm);
    this.memberDetailForm.addControl('tiktok', this.tiktokForm);
    this.memberDetailForm.addControl('youtube', this.youtubeForm);
    this.memberDetailForm.addControl('memberConfidential', this.memberConfidentialForm);
    this.memberDetailForm.addControl('bankAccount', this.bankAccountForm);
  }

  get isCreate() {
    return this.action === 'create';
  }

  get isUpdate() {
    return this.action === 'update';
  }

  get hasDeletePermission() {
    const groups = this.membersForm.value.groups as Array<string>;
    const { user } = defaultTo(this.servicePermissions, { user: { delete: false } });
    const { delete: deleteList } = defaultTo(this.permittedGroups, { user: { delete: [] } }).user;

    return user.delete ||
      some(groups, guid => deleteList.includes(guid));
  }

  hasPermission(subCollectionName: string): boolean {
    const groups = this.membersForm.value.groups as Array<string>;
    const { administrate } = defaultTo(this.servicePermissions, { administrate: false });
    const { administrate: administrateList } = defaultTo(this.permittedGroups, { administrate: [] });

    switch (subCollectionName) {
      case 'bankAccounts':
        return administrate;
      case 'addresses':
        return administrate ||
          some(groups, guid => administrateList.includes(guid));
      case 'resetMemberMail':
        return administrate;
      default:
        return false;
    }
  }

  get publicMemberTag() {
    return (this.memberTagList);
  }

  get secretMemberTag() {
    return (this.confidentialTagList);
  }

  existsNonGroupedMaster(list: any[]) {
    return _filter(list, o => !get(o, 'parentMasterGroupId')).length > 0;
  }

  existsBelongedMaster(id: string, list: any[]): boolean {
    return !!find(list, ['parentMasterGroupId', id]);
  }

  /** Return whether error caused by account types. */
  hasInstagramAccountTypeError(connection: ConnectionInstagram | null) {
    const fetch: any = connection.connectionFetch;
    if (!connection || fetch.statusCode !== 110) {
      return false;
    }
    switch (connection.accountType) {
      case ConnectionInstagram.AccountTypeEnum.MEDIACREATOR:
      case ConnectionInstagram.AccountTypeEnum.BUSINESS:
        return false;
      default:
        return true;
    }
  }

  repairMemberData() {
    this.member.groups = (this.member.groups.length > 0) ?
      this.member.groups.map((list: any) => list.id) :
      [];

    if (this.member.publishStartAt) {
      const dateTime = this.member.publishStartAt;
      this.member.publishStartAt = new Date(dateTime);
      this.useOpenPeriodStartAt = true;
    } else {
      this.member.publishStartAt = new Date();
      this.useOpenPeriodStartAt = false;
    }

    if (this.member.occupation_master) {
      const occupation: any = this.member.occupation_master;
      this.member.occupation_master = occupation.id;
    } else {
      this.member.occupation_master = null;
    }

    if (this.member.industry_master) {
      const industry: any = this.member.industry_master;
      this.member.industry_master = industry.id;
    } else {
      this.member.industry_master = null;
    }

    if (this.member.job_master) {
      const job: any = this.member.job_master;
      this.member.job_master = job.id;
    } else {
      this.member.job_master = null;
    }

    if (this.member.member_status_master) {
      const memberStatus: any = this.member.member_status_master;
      this.member.member_status_master = memberStatus.id;
    } else {
      this.member.member_status_master = null;
    }

    if (this.secretConfidential) {
      this.secretConfidential.member_confidential_tag_masters = this.secretConfidential.member_confidential_tag_masters ?
        this.secretConfidential.member_confidential_tag_masters.map((list: any) => list.id) :
        [];
    }

    this.member.tag_masters = this.member.tag_masters ?
      this.member.tag_masters.map((list: any) => list.id) :
      [];

    if (this.member.connection_twitter) {
      const twitter: any = this.member.connection_twitter;
      this.member.connection_twitter = twitter.id;
    } else {
      this.member.connection_twitter = null;
    }

    this.member.connection_instagrams = (this.member.connection_instagrams.length > 0) ?
      this.member.connection_instagrams.map((list: any) => list.id) :
      [];

    if (this.member.connection_tik_tok) {
      const tiktok: any = this.member.connection_tik_tok;
      this.member.connection_tik_tok = tiktok.id;
    } else {
      this.member.connection_tik_tok = null;
    }

    if (this.member.connection_youtube) {
      const toutube: any = this.member.connection_youtube;
      this.member.connection_youtube = toutube.id;
    } else {
      this.member.connection_youtube = null;
    }

    if (this.member.bank_account) {
      const bankAcc: any = this.member.bank_account;
      this.member.bank_account = bankAcc.id;
    } else {
      this.member.bank_account = null;
    }
  }

  setDocumentsToForms() {
    // Set base data.
    this.membersForm.patchValue(_.cloneDeep(this.member) || {});
    if (this.secretConfidential) {
      this.memberConfidentialForm.patchValue(_.cloneDeep(this.secretConfidential) || {});
    }
    if (this.member.hasChildren) {
      const children = this.member.children || []; // escape nil.
      _.forEach(this.member.children, (c, i) => this.addChild(Number(i)));
      this.childrenForm.patchValue(_.cloneDeep(children));
    }
    const imageFiles = (fileModel: any, i: number) => {
      if (fileModel instanceof Object && fileModel.mime && fileModel.url) {
        const isImage = _.isString(fileModel.mime) && fileModel.mime.match(/image\/*/);
        const fileObj = fileModel.url ? fileModel : null;
        const displayThumbnail = () => {
          const elementRef = this.thumbnails && this.thumbnails.toArray()[i];
          if (!elementRef) {
            requestAnimationFrame(displayThumbnail);
            return;
          }
          this.displayThumbnails(fileObj, elementRef.nativeElement);
        };
        const ctrl = this.imagesForm.at(Number(i));
        // add control or patch value to control.
        if (!ctrl) {
          this.addImageForm({ data: fileObj, fileModel });
        } else {
          ctrl.patchValue({ data: fileObj, fileModel });
        }
        if (isImage) {
          displayThumbnail();
        }
      }
    };
    imageFiles(this.member.image1, 0);
    imageFiles(this.member.image2, 1);
    imageFiles(this.member.image3, 2);
    imageFiles(this.member.image4, 3);
  }

  setPersonalDocumentsToForms() {
    this.addressForm.patchValue(_.cloneDeep(this.address || {}));
    if (this.bankAccount) {
      this.hasBankAccount.setValue(true);
      this.bankAccountForm.patchValue(_.cloneDeep(this.bankAccount) || {});
    }
  }

  setConnectionDocumentsToForms() {

    if (this.member.connection_twitter) {
      this.twitterForm.patchValue(_.cloneDeep(this.member.connection_twitter) || {});

    }
    if (this.member.connection_instagrams) {
      this.member.connection_instagrams.forEach
        ((insta: any) => {
          if (insta.order === 0) {
            this.instagramForm.at(Number(0)).patchValue(_.cloneDeep(insta));
          } else {
            this.instagramForm.at(Number(1)).patchValue(_.cloneDeep(insta));
          }
        });
    }
    if (this.member.connection_tik_tok) {
      this.tiktokForm.patchValue(_.cloneDeep(this.member.connection_tik_tok) || {});

    }
    if (this.member.connection_youtube) {
      this.youtubeForm.patchValue(_.cloneDeep(this.member.connection_youtube) || {});
    }
  }

  getPersonalData() {
    const wait$ = of(null).pipe(delayWhen(() => {
      const ready$ = new Subject();
      const isSubscribed = () => {
        return this.servicePermissions && (this.servicePermissions.administrate || this.permittedGroups) ?
          ready$.next() :
          requestAnimationFrame(isSubscribed);
      };
      requestAnimationFrame(isSubscribed);
      return ready$;
    }));
    wait$
      .pipe(
      ).subscribe(() => {
        if (!this.hasPermission('addresses')) {
          this.address = null;
        }
        if (!this.hasPermission('bankAccounts')) {
          this.bankAccount = null;
        }
        this.setPersonalDocumentsToForms();
      }, err => console.error(err));
  }

  subscribeServicePermissions() {
    const authUid = this.afAuth.auth.currentUser.uid;
    this.apiService.getAdminUser({ uid: authUid })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((permission: any) => {
        if (permission.length > 0) {
          const permit = permission[0];
          if (permit.role === 'serviceAdmin') {
            this.servicePermissions = this.serviceAdmin;
          } else if (permit.role === 'groupAdmin') {
            this.servicePermissions = this.groupAdmin;
          } else {
            this.servicePermissions = this.groupEditor;
          }
        }
      }, err => console.error(err));
  }

  subscribeTagGroups() {
    this.apiService.getMasterGroup(
      'tag-masters',
      {
        parentMasterGroupId_null: true,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.tagGroupList = list;
      }
    );
  }

  subscribeTags() {
    this.apiService.getMasterGroup(
      'tag-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.memberTagList = list;
      }
    );
  }

  confidentialTagGroups() {
    this.apiService.getMasterGroup(
      'member-confidential-tag-masters',
      {
        parentMasterGroupId_null: true,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.confidentialTagGroupList = list;
      }
    );
  }

  confidentialTag() {
    this.apiService.getMasterGroup(
      'member-confidential-tag-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.confidentialTagList = list;
      }
    );
  }

  subscribeCategories() {
    this.apiService.getMasterGroup(
      'category-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.categoryList = list;
      }
    );
  }

  subscribeMemberSegments() {
    this.apiService.getMasterGroup(
      'member-status-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.memberSegmentList = list;
      }
    );
  }

  subscribeOrderSegments() {
    this.apiService.getMasterGroup(
      'project-kind-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.orderSegmentList = list;
      }
    );
  }

  subscribeOccupations() {
    this.apiService.getMasterGroup(
      'occupation-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.occupationList = list;
      }
    );
  }

  subscribeIndustries() {
    this.apiService.getMasterGroup(
      'industry-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.industryList = list;
      }
    );
  }

  subscribeJobs() {
    this.apiService.getMasterGroup(
      'job-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      list => {
        this.jobList = list;
      }
    );
  }

  assignOrderChange() {
    const { uid } = this.aRoute.snapshot.params;
    this.apiService.getEachMember(uid, '')
      .pipe(
        takeUntil(this.onDestroy$),
        filter(doc => doc.projects !== this.member.projects)
      )
      .subscribe(
        doc => {
          const { order } = doc;
          _.assign(this.member, { order });
          this.membersForm.patchValue({ order });
        }, err => console.error(err)
      );
  }

  set today(x: Date) { }
  get today() { return new Date(); }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return Array.isArray(this.cachedList) ? this.cachedList.length : 0;
  }

  async getMemberWorks() {
    this.pending = true;
    this.hasNext = true;
    this.cachedList = null;
    this.queryTotal = null;
    this.totalMember = null;
    const query = {} as any;
    // tslint:disable-next-line: no-string-literal
    query['_limit'] = '-1';
    query['entries.member'] = this.member.id;
    // tslint:disable-next-line: no-string-literal
    query['_sort'] = 'id:DESC';
    this.baseQuery = cloneDeep(query);
    const projectList = () => {
      return new Promise<Project[]>(pjresolve => {
        this.apiService.getProject(query)
          .subscribe(projects => {
            _.forEach(projects, eachpj => {
              const entrieddata: any = _.find(eachpj.entries, ['member', this.member.id]);
              const status = _.find(this.statusList, ['value', entrieddata.status]);
              // tslint:disable-next-line: no-string-literal
              eachpj['statuslabel'] = status ? status.label : '';
            });
            pjresolve(projects);
          });
      });
    };
    this.works = await projectList();
    this.pending = false;
    this.queryTotal = this.works.length;
    this.totalMember = this.works.length;
    this.cachedList = _.slice(this.works, 0, this.maxSize);
    this.cachedExtraList = _.slice(this.works, this.maxSize, this.works.length);
    this.dataStream.next(this.cachedList);
    if (this.queryTotal > this.cachedList.length) {
      this.hasNext = true;
    } else {
      this.hasNext = false;
    }
  }

  public async searchNextProjects(selectAll?: boolean) {
    if (!this.baseQuery
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.queryWithRange.search._start = this.cachedList.length;
    this.queryWithRange.search._limit = selectAll ? this.queryTotal - this.cachedList.length : this.maxSize;
    await this.delay(1500);
    this.cachedList.push(..._.slice(this.cachedExtraList, 0, this.maxSize));
    this.cachedExtraList = _.slice(this.cachedExtraList, this.maxSize, this.cachedExtraList.length);
    if (this.cachedList.length < this.queryTotal) {
      this.hasNext = true;
    } else {
      this.hasNext = false;
    }
    this.dataStream.next(this.cachedList);
    this.pending = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  hasGroup(guid: string) {
    const groups: string[] = this.membersForm.value.groups;
    return groups === null ? false : groups.includes(guid);
  }

  groupChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const guid = event.source.value;
    const groupsCtrl = this.membersForm.get('groups');
    const groups: string[] = groupsCtrl.value || [];
    const groupPos = _.indexOf(groups, guid);
    // Add or remove from groups.
    if (checked) {
      groups.push(guid);
      groupsCtrl.setValue(_.union(groups));
    } else if (groupPos >= 0) {
      groups.splice(groupPos, 1);
      groupsCtrl.setValue(groups);
    }
  }

  hasMemberTag(tagId: string) {
    const tags: string[] = this.membersForm.value.tag_masters || [];
    return tags.includes(tagId);
  }

  memberTagChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const tagId = event.source.value;
    const tagsCtrl = this.membersForm.get('tag_masters');
    const tags: string[] = tagsCtrl.value || [];
    const tagPos = _.indexOf(tags, tagId);
    // Add or remove from tags.
    if (checked) {
      tags.push(tagId);
      tagsCtrl.setValue(_.union(tags));
    } else if (tagPos >= 0) {
      tags.splice(tagPos, 1);
      tagsCtrl.setValue(tags);
    }
  }

  hasSecretMemberTag(tagId: string) {
    const tags: string[] = this.memberConfidentialForm.value.member_confidential_tag_masters || [];
    return tags.includes(tagId);
  }

  secretMemberTagChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const tagId = event.source.value;
    const tagsCtrl = this.memberConfidentialForm.get('member_confidential_tag_masters');
    const tags: string[] = tagsCtrl.value || [];
    const tagPos = _.indexOf(tags, tagId);
    // Add or remove from tags.
    if (checked) {
      tags.push(tagId);
      tagsCtrl.setValue(_.union(tags));
    } else if (tagPos >= 0) {
      tags.splice(tagPos, 1);
      tagsCtrl.setValue(tags);
    }
  }

  searchAddress() {
    const code = this.addressForm.get('postalCode').value;
    const addressCtrl = this.addressForm.get('address');
    addressCtrl.setValue('検索した住所');
  }

  set imageForm(x: FormGroup) { }
  get imageForm(): FormGroup {
    return new FormGroup({
      data: new FormControl(null),
      fileModel: new FormControl(null),
      prevIndex: new FormControl(0),
    });
  }

  addImageForm(value: { data: any, fileModel?: any } = { data: null }) {
    const currentLength = this.imagesForm.controls.length;
    const ctrl = this.imageForm;
    ctrl.patchValue({
      data: new FormControl(value.data || null),
      fileModel: new FormControl(value.fileModel || null),
      prevIndex: new FormControl(currentLength),
    });
    this.imagesForm.push(ctrl);
  }

  set childForm(x: FormGroup) { }
  get childForm(): FormGroup {
    return new FormGroup({
      gender: new FormControl(null),
      birthDay: new FormControl(null),
    });
  }

  watchChildrenStateChange() {
    merge(
      this.childrenForm.valueChanges,
      this.membersForm.get('hasChildren').valueChanges
    ).pipe(
      map(v => {
        const isBool = typeof v === 'boolean';
        const hasChildren = isBool ? v : this.membersForm.value.hasChildren;
        const children = isBool ? this.childrenForm.value : v;
        return hasChildren ? children : null;
      })
    )
      .subscribe(children => {
        this.membersForm.patchValue({ children });
      });
  }

  addChild(index = 0) {
    const ctrl = this.childForm;
    this.childrenForm.insert(index, ctrl);
  }

  removeChild(index = 0) {
    this.childrenForm.removeAt(index);
  }

  fileSelectChange(file: File, index: number) {
    const ctrl = this.imagesForm.at(index);
    const maxSize = 50 * 1024 ** 2;
    if (file instanceof File && file.size > maxSize) {
      this.filechoosers.toArray()[index].removeFile();
      return alert('ファイルサイズが50MBを越えています');
    }
    // Attach file
    if (file instanceof File) {
      ctrl.patchValue({ data: file, fileModel: null });
    } else {
      ctrl.patchValue({ data: null, fileModel: null });
    }
    ctrl.markAsDirty();
  }

  async displayThumbnails(file: any, cover: HTMLElement) {
    if (file instanceof File && file.type.match(/image\/.*/)) {
      const component = new FileChooserComponent();
      const url = await component.convertToDataURL(file);
      this.renderer2.setStyle(cover, 'display', 'block');
      this.renderer2.setStyle(cover, 'background-image', `url(${url})`);
    } else if (file instanceof Object && file.mime.match(/image\/.*/) && file.url) {
      this.renderer2.setStyle(cover, 'display', 'block');
      this.renderer2.setStyle(cover, 'background-image', `url(${file.url})`);
    } else {
      this.renderer2.setStyle(cover, 'display', 'none');
      this.renderer2.removeStyle(cover, 'background-image');
    }
  }

  /**
   * Upload files if some new file attached.
   */
  async prepareFiles() {
    const fileList: File[] = [];
    this.imagesForm.controls.forEach(async (ctrl, i) => {
      const data: File = ctrl.value.data;
      let fileData: File;
      if (data !== null) {
        if (data.size === 0) {
          fileData = null;
        } else if (data.size > 0) {
          fileData = data;
        }
      } else {
        fileData = null;
        if (this.membersForm.get('image1').value
          || this.membersForm.get('image2').value
          || this.membersForm.get('image3').value
          || this.membersForm.get('image4').value) {
          switch (i) {
            case 0:
              this.membersForm.patchValue({
                image1: null
              });
              break;
            case 1:
              this.membersForm.patchValue({
                image2: null
              });
              break;
            case 2:
              this.membersForm.patchValue({
                image3: null
              });
              break;
            case 3:
              this.membersForm.patchValue({
                image4: null
              });
              break;
            default:
              break;
          }
        }
      }
      fileList.push(fileData);
    });
    return fileList;
  }

  repairRelationData() {
    const address = this.addressForm.value;
    const child = this.childrenForm.value;
    let memberConfi = this.memberConfidentialForm.value;
    let twitter = this.twitterForm.value;
    let instagram = this.instagramForm.value;
    let tiktok = this.tiktokForm.value;
    let youtube = this.youtubeForm.value;

    if (!address.postalCode) {
      address.postalCode = null;
    }

    if (isNil(memberConfi.remarks) && isEmpty(memberConfi.member_confidential_tag_masters)) {
      memberConfi = null;
    }

    if (isNil(twitter.screenName)) {
      twitter = null;
    }

    if (!isEmpty(instagram)) {
      const instaList = [];
      instagram.forEach((insta: any, i: number) => {
        insta.order = i;
        instaList.push(insta);
      });
      instagram = instaList;
    }

    if (isNil(tiktok.uniqueId)) {
      tiktok = null;
    }

    if (isNil(youtube.idOfResponse)) {
      youtube = null;
    }

    this.membersForm.patchValue({
      address,
      children: child,
      member_confidential: memberConfi,
      connection_twitter: twitter,
      connection_instagrams: instagram,
      connection_tik_tok: tiktok,
      connection_youtube: youtube
    });
  }

  setJST(birthDate: any) {
    const date = new Date(birthDate);
    // console.log('current : ', date);
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    // japan UTC + 9
    const offset = 9;
    const japanTime = utc + (3600000 * offset);
    const nd = new Date(japanTime);
    nd.setHours(0);
    nd.setMinutes(0);
    nd.setSeconds(0);
    // console.log('nihon : ', nd);
    return nd;
  }

  async createMember() {
    this.repairRelationData();
    const updateImgList = await this.prepareFiles();
    const sentMail = String(this.sendWelcomeMail);
    console.log('sent mail ', sentMail);
    const body = new FormData();
    const memberData = { ...this.membersForm.value };
    // set JST time
    if (memberData.birthDay) {
      memberData.birthDay = this.setJST(memberData.birthDay);
    } else {
      memberData.birthDay = null;
    }
    if (memberData.children.length > 0) {
      memberData.children = _.map(memberData.children, children => {
        if (children.birthDay) {
          children.birthDay = this.setJST(children.birthDay);
        } else {
          children.birthDay = null;
        }
        return children;
      });
    }
    if (this.useOpenPeriodStartAt) {
      memberData.publishStartAt = memberData.publishStartAt.toISOString();
    } else {
      memberData.publishStartAt = null;
    }
    body.append('data', JSON.stringify(memberData));
    body.append('files.image1', updateImgList[0]);
    body.append('files.image2', updateImgList[1]);
    body.append('files.image3', updateImgList[2]);
    body.append('files.image4', updateImgList[3]);
    JSON.stringify(body);
    return new Promise<any>((resolve, reject) => {
      this.apiService.createMember(body, sentMail).subscribe(
        (data) => {
          resolve(data);
        }, err => {
          reject(err);
        }
      );
    });
  }

  async updateBank(uid: number) {
    if (!this.bankAccount && this.hasBankAccount.value) {
      this.bankAccountForm.patchValue({
        member: uid
      });
      this.apiService.createBankAccount(this.bankAccountForm.value)
        .subscribe((doc: any) => {
          if (doc) {
            return true;
          }
        }, (err: any) => console.error(err));
    } else if (this.bankAccount && !this.hasBankAccount.value) {
      if (this.bankAccountForm.get('id').value) {
        this.apiService.deleteBankAccount(this.bankAccountForm.get('id').value)
          .subscribe((doc: any) => {
            if (doc) {
              return true;
            }
          }, (err: any) => console.error(err));
      }
    } else { return true; }
  }

  async updateGroup(uid: number) {
    const [addedGroups, removedGroups] = [
      difference(this.membersForm.value.groups, this.member.groups),
      difference(this.member.groups, this.membersForm.value.groups)
    ];
    if (addedGroups.length > 0) {
      addedGroups.forEach((add: any) => {
        this.member.groups.push(add);
      });
    }
    if (removedGroups.length > 0) {
      removedGroups.forEach((add: any) => {
        this.member.groups = this.member.groups.filter(item => item !== add);
      });
    }
    this.member.groups.forEach(ele => {
      let gtdata = [];
      this.apiService.getGroups({ id: ele }).subscribe
        (data => {
          const member = data.map(getdata => getdata.members);
          gtdata = [];
          member[0].forEach(mem => {
            gtdata.push(mem.id);
          });
          gtdata.push(uid);
          this.apiService.updateGroup(ele, { members: gtdata }).subscribe
            (() => console.log(' all update sucess'));
        });
    });
  }

  async updateMember() {
    const { uid } = this.aRoute.snapshot.params;
    this.repairRelationData();
    const updateImgList = await this.prepareFiles();
    const groups = this.membersForm.value.groups;
    const groupList = [];
    this.groupList.forEach(gplist => {
      groups.forEach(group => {
        if (gplist.id === group) {
          groupList.push(group);
        }
      });
    });
    this.membersForm.value.groups = groupList;
    const memberData = { ...this.membersForm.value };
    const body = new FormData();
    if (!this.hasPermission('addresses')) {
      delete memberData.address;
    }
    // set JST time
    if (memberData.birthDay) {
      memberData.birthDay = this.setJST(memberData.birthDay);
    } else {
      memberData.birthDay = null;
    }
    if (memberData.children.length > 0) {
      memberData.children = _.map(memberData.children, children => {
        if (children.birthDay) {
          children.birthDay = this.setJST(children.birthDay);
        } else {
          children.birthDay = null;
        }
        return children;
      });
    }
    if (this.useOpenPeriodStartAt) {
      memberData.publishStartAt = memberData.publishStartAt.toISOString();
    } else {
      memberData.publishStartAt = null;
    }
    body.append('data', JSON.stringify(memberData));
    body.append('files.image1', updateImgList[0]);
    body.append('files.image2', updateImgList[1]);
    body.append('files.image3', updateImgList[2]);
    body.append('files.image4', updateImgList[3]);
    JSON.stringify(body);
    return new Promise<any>((resolve, reject) => {
      this.apiService.updateMember(uid, body).subscribe(
        async (data) => {
          await this.updateBank(uid);
          await this.updateGroup(uid);
          resolve(data);
        }, err => {
          reject(err);
        }
      );
    });
  }

  async resetMemberEmail(oldEmail) {
    const [applyText, cancel] = ['OK', false];
    await this.emailUpdateService.sendEmailUpdate(oldEmail);
  }

  async registerMember() {
    const dialogData: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({
      data: dialogData,
      disableClose: true
    });

    this.pendingCount++;
    try {
      dialogData.text = `メンバーを${this.isCreate ? '作成' : '更新'}しています...`;
      if (this.isCreate || !this.member) {
        await this.createMember();
        await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));
      } else {
        await this.updateMember();
        await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));
      }

      dialogData.text = `メンバーの${this.isCreate ? '作成' : '更新'}が完了しました`;
      dialogData.hiddenBar = true;
      await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1500));

      dialog.close();
      this.router.navigate([this.path.user.list]);
      return;
    } catch (err) {
      console.error(err);
      dialog.close();
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      const errorCode = err.error.statusCode;
      const errorMessage = err.error.message;
      if (errorMessage === 'duplicate foreignKey.') {
        alert('既に登録済みの連携用IDが指定されています。');
      } else if ((this.isCreate || !this.member) && errorCode === 409) {
        alert('すでに使用されているメールアドレスのため、登録できませんでした。');
      } else if (this.isCreate || !this.member) {
        alert('ユーザー作成に失敗しました');
      } else {
        alert('ユーザー更新に失敗しました');
      }
    }
    this.pendingCount--;
  }

  makeWithdrawMember() {
    const { uid } = this.aRoute.snapshot.params;
    const confirmInputDialog = this.dialog.openConfirmInputDialog({
      data: {
        text: [
          'メンバーを退会させるには、メールアドレスを入力してください。',
          `${this.membersForm.value.email1}`,
        ],
        needInput: true,
        needInputText: `${this.membersForm.value.email1}`,
        apply: true,
        cancel: true,
        applyText: '退会',
        applyButtonColor: 'warn',
        cancelButtonColor: 'primary',
      }
    });
    confirmInputDialog.afterClosed()
      .pipe(filter(result => result))
      .subscribe(async () => {
        const dialogData: LodingDialogData = { text: '' };
        const loadingDialog = this.dialog.openLoadingDialog({
          data: dialogData,
          disableClose: true,
        });

        dialogData.text = 'メンバーの退会処理を実行中です...';
        this.apiService.deleteMember(uid)
          .subscribe(async () => {
            await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));

            dialogData.text = 'メンバーの退会が完了しました';
            dialogData.hiddenBar = true;

            await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1500));

            loadingDialog.close();
            this.router.navigate([this.path.user.list]);
          }, async err => {
            console.error(err);
            loadingDialog.close();
            // Waiting for dialog closing because native alertdialog make block to scripts.
            await new Promise(resolve => {
              loadingDialog.afterClosed().subscribe(resolve);
              loadingDialog.close();
            });
            alert('メンバーの退会に失敗しました');
          });
      });
  }

  emptyToNull(ctrl: AbstractControl) {
    if (_.isEmpty(ctrl.value)) {
      ctrl.setValue(null);
    }
  }

  log(...arg) {
    console.log(...arg);
  }
}
