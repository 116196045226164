import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialogRef } from '@angular/material';
import { LoadingDialogComponent, LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { DialogService } from './dialog.service';
import { MatchingService } from './matching.service';

@Injectable({
  providedIn: 'root'
})
export class MemberEmailUpdateService {

  constructor(private apiService: MatchingService,
    private dialog: DialogService,
    private afAuth: AngularFireAuth) { }

  async sendEmailUpdate(oldEmail) {
    const [applytext , cancel ] = ['送信' , true];
    const dialog = this.dialog.openMemberEmailUpdateDialog({
      data: {
        applytext,
        cancel,
        text: '新しいメールアドレスを入力してください',
      }
    });
    const data: LodingDialogData = {
      text: 'メンバーのメール更新メールを送信しています...',
    };
    let responseDialog: MatDialogRef<any>;
    
    return new Promise((resolve) => {
      dialog.afterClosed().subscribe(async newEmail => {
        if(newEmail) {
          responseDialog = this.dialog.openLoadingDialog({
            autoFocus: false,
            disableClose: true,
            data,
          });
          const requestObject = {
             newEmail : newEmail,
             oldEmail : oldEmail,
             redirectTo : 'member'
          }
         this.apiService.sendUpdateEamilMailByAdmin(requestObject).subscribe(async(response)=>{
          if(response.status === 200){
            resolve(true);
            data.hiddenBar = true;
            data.text = '更新メールを送信しました。';
            await new Promise<void>(r => setTimeout(() => r(), 1500));
            responseDialog.close();
          }
         },async (error)=>{
          console.error(error);
          data.color = 'warn';
          data.value = 100;
          data.mode = 'determinate';
          data.text = '更新メールを送信できませんでした。';
          await new Promise<void>(r => setTimeout(() => r(), 2000));
          responseDialog.close();
         });
        }
      });
    });
  }
}
